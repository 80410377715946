import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);

	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languages && props.languages !== languages) {
			setLanguages(props.languages);
		} 
	}, [props.languages]);
	
	useEffect(() => {
		if(userID && token && organizationID && languages) {
			setFieldsets([
				{fieldset_name: `${props.objExists(props, 'language.language_id') ? 'Update' : 'Add'} Language`,
					fields: [
						{field_type: 'select',
						field_name: 'link_from',
						field_label: 'Language',
						field_placeholder: 'Select language',
						field_value: null,
						field_required: false,
						field_validate: false,
						option_groups: [
							{options: 
								languages && Array.isArray(languages) &&
								languages.map(language => {
									return {option_name: language.language_name,
											option_value: language.language_id}
								})}
							],
						field_order: 1,
						},
						{field_type: 'switch',
						field_name: 'link_default',
						field_label: 'Default Language',
						field_value: props.objGetValue(props, 'language.language_org_default') === 0 ||
									props.objGetValue(props, 'language.language_org_default') === false ||
									props.objGetValue(props, 'language.language_org_default') === 'false' ?
										false : true,
						field_required: false,
						field_validate: false,
						field_order: 2},
						{field_type: 'hidden',
						field_name: 'process',
						field_value: 'add_link',
						},
						{field_type: 'hidden',
						field_name: 'link_type',
						field_value: 'languageToOrganization',
						},
						{field_type: 'hidden',
						field_name: 'link_to',
						field_value: organizationID,
						},
						{field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID,
						},
						{field_type: 'hidden',
						field_name: 'token',
						field_value: token,
						},
						{field_type: 'hidden',
						field_name: 'return_bool',
						field_value: 'true'
						}
				]}]);
		}else if(fieldsets) {
			setFieldsets(null);
		}
	}, [userID, token, organizationID, languages]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					name="org-language-assign"
					returnObject={true}
					fieldOnChange={null}
					fieldOnLoad={null}
					formActions={{formSubmit: handleSubmit, formCancel: handleCancel}} />
			);
		}
	}, [fieldsets]);
	
	// FUNCTIONS
	function handleSubmit(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}else{
			props.setModal(null);
		}
	}
	
	return form
}