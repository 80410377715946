import React, {useEffect, useState} from 'react';

import Control from 'components/atoms/Control';
import Switch from 'components/forms/elements/Switch';
import FormSlideAddEdit from 'components/forms/common_forms/FormSlideAddEdit.js';
import FormSlideshowAddEdit from 'components/forms/common_forms/FormSlideshowAddEdit.js';
import SlideShow from 'components/molecules/SlideShow';
import Slide from 'components/molecules/Slide'
import IconToken from 'components/icons/IconToken';
import ModalConfirmation from 'components/molecules/ModalConfirmation';


export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [slideModal, setSlideModal] = useState(null);
	const [slideForm, setSlideForm] = useState(null);
	const [curSlide, setCurSlide] = useState(null);
	const [curSlideshow, setCurSlideshow] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(!languageCode && props.ssLanguage) {
			setLanguageCode(props.ssLanguage);
		}else if(!languageCode){
			setLanguageCode('en');
		}
	}, [props.ssLanguage]);
	
	useEffect(() => {
		slideModal &&
		setSlideForm(
			<FormSlideAddEdit 
				{...props}
				slide={slideModal.slide ? slideModal.slide : null}
				slideshow={slideModal.slideshow ? slideModal.slideshow : null}
				media={props.objExists(props.files, `uploads.slides`) ? 
						props.files.uploads.slides : null}
				userID={props.userID}
				organizationID={props.organizationID}
				token={props.token}
				languageCode={languageCode}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={addEdit}
			/>	
		)
	}, [slideModal, props.media, props.files]);
	
	useEffect(() => {
		if(slideModal && slideForm) {
			if(slideModal.action === 'addEdit') {
				props.setModal(slideForm)	
			}
		}
	}, [slideModal, slideForm, curSlide, curSlideshow, props.media, props.files]);
	
	// FUNCTIONS
	function showSlideshow(slideshow) {
		props.setModal(
			<SlideShow {...props}
			  slides={slideshow.slides[languageCode]}
			  aspectRatio={slideshow.slideshow_asspect_ratio ? slideshow.slideshow_asspect_ratio : '16:9'}
			   />,
			true
		);
	}
	
	function previewSlide(slide, aspectRatio, lang = languageCode) {
		props.setModal(
			<Slide {...props}
					slide={slide}
					aspectRatio={aspectRatio}
					languageCode={lang} />,
			true	
		);
	}
	
	function loadAddEditSlide(slide = null, slideshow = null) {
		setSlideModal({
			action: 'addEdit',
			slide: slide,
			slideshow: slideshow,
		})
		if(slide) {
			setCurSlide(slide);
		}else{
			setCurSlide(null);
		}
		if(slideshow) {
			setCurSlide(slideshow);
		}else{
			setCurSlide(null);
		}
	}
	
	function loadAddEditSlideshow(slideshow = null) {
		props.setModal(
			<FormSlideshowAddEdit 
				{...props}
				slideshow={slideshow ? slideshow : null}
				userID={props.userID}
				organizationID={props.organizationID}
				token={props.token}
				languageCode={languageCode}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={addEdit}
			/>
		)
	}
	
	function addEdit(params) {
		props.setModal(null);
		setSlideModal(null);
		console.log("ADD EDIT PARAMS", params);
		props.adminLoadState(params, 'site', 'reload.persona');
	}
	
	function loadRemove(id, parent_id, link_type) {
		props.setModal(
			<ModalConfirmation
				{...props}
				message={`Are you sure you want to permanently remove this ${link_type === 'slideToSlideshow' ? 
							'slide' : 'slideshow'}?`}
				actionCancel={()=>{props.setModal(null)}}
				actionAccept={()=>{remove(id, parent_id, link_type)}}
			/>
		)
	}
	
	function remove(id, parent_id, link_type) {
		props.setModal(null);
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'link_type', value: link_type},
			{name: 'link_from', value: id},
			{name: 'link_to', value: parent_id},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: 'true'},
		]
		props[`${props.location}LoadState`](params, 'links', 'reload.persona');
	}
	
	function publish(value, args) {
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'link_type', value: args.link_type},
			{name: 'link_from', value: args.link_from},
			{name: 'link_to', value: args.link_to},
			{name: 'link_field', value: 'link_published'},
			{name: 'link_value', value: value === true ? 'true' : 'false'},
		]
		props[`${props.location}LoadState`](params, 'links', 'reload.persona');
	}
	
	// console.log("PERSONA SLIDES", props.slideshows);
	return (
		<div className="profile-component">
			<h5>Slideshows</h5>
			<div>
				{props.slideshows && 
					Array.isArray(props.slideshows)  && 
					props.slideshows.length > 0 &&
					props.slideshows.map((slideshow, index) => {
						return (
							<div className="slide-show-admin" key={`sldShwAdmn${index}`}>
							<div className="profile-component-header">
								<div className="content">
									{slideshow.slideshow_name} | {slideshow.slideshow_aspect_ratio}
								</div>
								<div className="ctl">
									<IconToken icon="preview" 
												action={()=>{showSlideshow(slideshow)}}
												tooltip="Preview Slideshow" />
									<IconToken icon="edit" 
												action={()=>{loadAddEditSlideshow(slideshow)}}
												tooltip="Edit Slideshow" />
									<IconToken icon="add" 
												action={()=>{loadAddEditSlide(null, slideshow)}}
												tooltip="Add Slide" />
									<IconToken icon="trash" 
										action={()=>{loadRemove(slideshow.slideshow_id, props.organizationID, 'slideshowToOrganization')}}
										tooltip="Remove Slideshow" />
								</div>
								<Switch label="Publish"
										value={slideshow.slideshow_org_published ? true : false}
										params={{
											link_from: slideshow.slideshow_id,
											link_to: props.organizationID,
											link_type: 'slideshowToOrganization'
											}}
										action={publish} />
							</div>
							{props.objExists(slideshow, `slides.${languageCode}`) &&
								Array.isArray(slideshow.slides[languageCode]) && 
								slideshow.slides[languageCode].length > 0 &&
								<div className="slide-show-slide-set">
									{slideshow.slides[languageCode].map((slide, index) => {
										return (
											<div key={`sldShwSld${index}`} className="slide-show-slide" 
													style={{backgroundImage: props.objExists(slide, 'media.media_file_name') ? 
																`url("${props.localizeURL(slide.media.media_file_path)}${slide.media.media_file_name}")` : 'none'}}>
												<div className="slide-title-bar">
													{`Slide ${index + 1}`}
													<div className="slide-ctl">
														<IconToken icon="preview" 
															action={()=>{previewSlide(slide, slideshow.slideshow_aspect_ratio, languageCode)}}
															tooltip="view Slide" />
														<IconToken icon="edit" 
																	action={()=>{loadAddEditSlide(slide, slideshow)}}
																	tooltip="Edit Slide" />
														<IconToken icon="trash" 
															action={()=>{loadRemove(slide.slide_id, slideshow.slideshow_id, 'slideToSlideshow')}}
															tooltip="Remove Slide" />
													
													</div>
												</div>
											</div>
										)
									})}
								</div>
							}
						</div>	
						)
					})
				}
			</div>
			{!props.slideshows || 
				!Array.isArray(props.slideshows) || 
				(Array.isArray(props.slideshows) && props.slideshows.lenght <= 0) ?
				<Control icon="add" label="Add a Slide" showLabel={true} action={loadAddEditSlide} /> :
				<Control icon="add" label="Add a Slideshow" showLabel={true} action={loadAddEditSlideshow} />
			}
			
		</div>
	)
}