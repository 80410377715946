import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';
import MenuSetComponent from 'components/navigation/MenuSetComponent';
import MenuSetAction from 'components/navigation/MenuSetAction';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [item, setItem] = useState(null);
  const [moduleType, setModuleType] = useState(null);

  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.module && languageCode) {
      if(props.module.module_component !== null &&
          props.module.module_component !== '') {
        setModuleType('component');
      }else if(props.objExists(props.module, `${languageCode}.module_link`) && props.module[languageCode].module_link !== null &&
                props.module[languageCode].module_link !== '') {
        setModuleType('link');          
      }else{
        setModuleType('text');
      }
    }
  }, [props.module, languageCode]);
  
  useEffect(() => {
    if(moduleType) {
      switch (moduleType) {
        case 'component':
          setItem(<MenuSetComponent {...props}
                                    moduleComponent={props.module.module_component} 
                                    modulePlaceholder={props.objExists(props.module, `${languageCode}.module_name`) ? props.module[languageCode].module_name : ''}
                                    module={module}
                                    languageCode />);
          break;
        case 'link':
          setItem(<Link to={`/${props.module[languageCode].module_link}`} content={props.module[languageCode].module_name} />);
          break;
        case 'text':
        default:
          setItem(<div></div>);
          break;
      }
    }
  }, [moduleType]);
 
 // console.log("Menu Module", props.module); 
 return(
   item
 ) 
}