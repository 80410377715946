import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import List from 'components/molecules/List';
import CardValueTag from 'components/molecules/CardValueTag';
import FormOrgLanguageAssign from 'components/forms/common_forms/FormOrgLanguageAssign';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [addLanguages, setAddLanguages] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	  
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languages && props.languages !== languages) {
			setLanguages(props.languages);
		} 
	}, [props.languages]);
	
	useEffect(() => {
		if(languages) {
			let availableLanguages = [];
			{props.site.persona.languages && Array.isArray(props.site.persona.languages) &&
				props.site.persona.languages.map((siteLanguage, index) => {
					if(!languages.find(personaLanguage => personaLanguage.language_id === siteLanguage.language_id)) {
						availableLanguages.push(siteLanguage);
					}
				})
			}
			setAddLanguages(availableLanguages);
		} 
	}, [props.site.persona.languages, languages]);
	
	// FUNCTIONS
	function loadAddLanguage(type) {
		props.setModal(
			<FormOrgLanguageAssign {...props}
				userID={userID}
				token={token}
				organizationID={organizationID}
				languages={addLanguages}
				name="Add Site Language"
				actionSubmit={assignLanguage} / >
		)
	}
	
	function assignLanguage(params) {
		props.setModal(null);
		//console.log('TEST params', params);
		props.adminLoadState(params, 'links', 'reload.persona');
	}
	
	function updateLanguageDefault(value, params) {
		if(params) {
			if(value) {
				const removeLanguageDefaultsParams = [
					{name: 'process', value: 'remove_link_defaults'},
					{name: 'link_to', value: organizationID},
					{name: 'link_type', value: 'languageToOrganization'},
				]
				props.adminLoadState(removeLanguageDefaultsParams, `links`);
			}
			const updateParams = [
				{name: 'process', value: 'update_value'},
				{name: 'field_id', value: params.content_id},
				{name: 'field_name', value: 'language_org_default'},
				{name: 'field_value', value: value},
				{name: 'field_parent', value: organizationID},
				{name: 'update_id', value: userID},
			]
			props.adminLoadState(updateParams, `common_tasks`, `reload.persona`);
		}
	}
	  
	function loadRemoveLanguage(link_id, params) {
		props.setModal(
			<ModalConfirmation {...props}
				message={`Are you sure you wish to remove this language?`}
				actionAccept={()=>{removeLanguageLink(link_id)}} />
		)
	}
	
	function removeLanguageLink(language_id) {
		props.setModal(null);
		const linkParams = [
		  {name: 'process', value: 'update_links'},
		  {name: 'link_from', value: language_id},
		  {name: 'link_to', value: organizationID},
		  {name: 'link_type', value: 'languageToOrganization'},
		  {name: 'link_field', value: 'link_removed'},
		  {name: 'link_value', value: true},
		  {name: 'user_id', value: userID},
		  {name: 'token', value: token}
		]
		props.adminLoadState(linkParams, 'links', 'reload.persona');
	}

    return (
		<div className="list-set">
			<h4>Site Languages</h4>
			<div className="info-block margin-bottom-2em">
				English is the default site language if no languages have been added but <em>must</em> be added if multiple languages are required.
			</div>
			<div>
				{languages && languages.map((language, index) => {
					return (
				  	<CardValueTag {...props}
									key={`prsnLngTag${index}`}
									content={language.language_name}
									contentID={language.language_id}
									editable={true}
									actionRemove={loadRemoveLanguage}
									showOptions={true}
									contentOptions={
										[
										  {option_name: 'Default Language',
										  option_value: language.language_org_default ? true : false,
										  option_type: 'switch',
										  option_action: updateLanguageDefault}
										]
									} />
					)
			  	})}
			</div>
			{addLanguages && Array.isArray(addLanguages) && addLanguages.length > 0 &&
				<div className="list-set-ctl">
					<Control icon="add" label="Add a language" showLabel={true} action={loadAddLanguage} />
				</div>	
			}
		</div>
	)
}