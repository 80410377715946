import React, {useEffect, useState} from 'react';

import AdminCoreModules from 'components/admin/core/AdminCoreModules';
import AdminCoreMakeFont from 'components/admin/core/AdminCoreMakeFont';
import AdminCorePaymentOptions from 'components/admin/core/AdminCorePaymentOptions';
import AdminCoreShippingOptions from 'components/admin/core/AdminCoreShippingOptions';
import AdminCoreHosts from 'components/admin/core/AdminCoreHosts';
import AdminCoreLanguages from 'components/admin/core/AdminCoreLanguages';
import AdminCorePermissions from 'components/admin/core/AdminCorePermissions';

export default (props) => {
  const [coreComponent, setCoreComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);
  const [isSuper, setIsSuper] = useState(false);
  const [userAccess, setUserAccess] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    //console.log("Access", userAccess);
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    if(props.objExists(props.pgParams, 'activity') && userID && token && organizationID && languageCode) {
        switch(props.pgParams.activity) {
          case 'modules':
            setCoreComponent(<AdminCoreModules {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'make-font':
            setCoreComponent(<AdminCoreMakeFont {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'payment-options':
            setCoreComponent(<AdminCorePaymentOptions {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'shipping-options':
            setCoreComponent(<AdminCoreShippingOptions {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'hosts':
            setCoreComponent(<AdminCoreHosts {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'languages':
            setCoreComponent(<AdminCoreLanguages {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          case 'permissions':
            setCoreComponent(<AdminCorePermissions {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  userAccess={userAccess}
                                  isSuper={isSuper}
                                  pgParams={props.pgParams} />);
            break;
          default:
            break;
        }
    }
  }, [props.admin, props.pgParams, userID, organizationID, token, organizationName, languageCode, userAccess]);


  return userAccess && userAccess.super ? coreComponent : "ACCESS DENIED"
}
