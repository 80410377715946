import React, {Fragment} from 'react';

import ListItem from 'components/molecules/ListItem';


export default (props) => {

  return (
    <Fragment>
      {props.listName && <div className="ul-label">{props.listName}</div>}
      <ul className={`ul${props.listBox ? ' box' : ''}${props.inline ? ' inline' : ''}`}>

        {props.list && Array.isArray(props.list) &&
          props.list.map((listItem, index) => {
            return <ListItem {...props}
                              key={`lstItm${index}`}
                              item={listItem.name}
                              id={listItem.id}
                              type={listItem.type ? listItem.type : null}
                              actionEdit={props.actionEditItem ? props.actionEditItem : null}
                              actionRemove={props.actionRemoveItem ? props.actionRemoveItem : null}
                              icon={props.listIcon ? props.listIcon : false}
                              inline={props.listBox || props.inline ? true : false} />
          })
        }
      </ul>
    </Fragment>
  )
}
