import React, {useEffect, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import IconSVG from 'components/icons/IconSVG';
import Loader from 'components/atoms/Loader';
import Switch from 'components/forms/elements/Switch';
import FormDialogBox from 'components/forms/FormDialogBox';
import ProjectOrder from 'components/profiles/projects/ProjectOrder';
import ProjectFinancials from 'components/profiles/projects/ProjectFinancials';
import TaskInline from 'components/profiles/tasks/TaskInline';
import TaskApproval from 'components/profiles/tasks/TaskApproval';
import Control from 'components/atoms/Control';
import Link from 'components/navigation/Link';

import PrintShell from 'components/print/PrintShell';

import * as utils from 'methods/site';

export default (props) => {
  if(!utils.objExists(props, 'user') && utils.objExists(props.children, 'user')) {
    props = {...props.children, ...utils};
  }else{
    props = {...props, ...utils};
  }

  const [projectApproved, setProjectApproved] = useState(false)
  const [project, setProject] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [activeTask, setActiveTask] = useState(null);
  const [activeTasks, setActiveTasks] = useState(null);
  const [projectTasks, setProjectTasks] = useState(null);
  const [projectApprovalTask, setProjectApprovalTask] = useState(null);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  
  // HOOKS
  useEffect(() => {
    document.title = "ADMIN : Project";
  }, []);
  
  useEffect(() => {
    return () => {
      if(props.isAdmin) {
        props.adminDeleteVal('current.project.tasks');
        props.adminDeleteVal('current.task_user');
      }
    }  
  }, [])

  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode	&&
    props.languageCode	!== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode	]);
  
  useEffect(() => {
    props.userAccess &&
    props.userAccess !== userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    props.project && 
    props.project !== project &&
    setProject(props.project);
  }, [props.project]);
  
  useEffect(() => {
    if(project && languageCode){
       if(Array.isArray(project.status)) {
         setStatus(project.status[0][languageCode] && project.status[0][languageCode].status_name);
       }
    }
  }, [project, languageCode]);
  
  useEffect(() => {
    if(props.objExists(project, 'tasks') && 
        Array.isArray(project.tasks) &&
        project.tasks.length > 0) {
          setTasks(project.tasks);
        }
  }, [project]);
  
  useEffect(() => {
    if(tasks && Array.isArray(tasks)) {
      // setActiveTask(getActiveTask(tasks));
      const aTasks = props.getActiveTasks(tasks, languageCode, true);
      if(Array.isArray(aTasks) && aTasks.length > 0) {
        let aTaskSet = [];
        aTasks.map(aTask => {
          aTaskSet.push(props.buildTasks(aTask));
        })
        aTaskSet.length > 0 &&
        setActiveTasks(aTaskSet[0]);
      }else{
        setActiveTasks(null);
      }
      
      tasksLoading &&
      setTasksLoading(false);
    }
  }, [tasks]);
  
  useEffect(() => {
    if(props.objExists(project, 'project_id') && 
      Array.isArray(activeTasks) && activeTasks.length > 0) {
        const pTasks = activeTasks.filter(task => 
                        (task.task_parent_type === 'taskToProject' && 
                        task.task_parent_link === props.projet.project_id) ||
                        (task.task_parent <= 0));
        if(pTasks && pTasks !== activeTasks) {
          setProjectTasks(pTasks);
        }
      }else if(!activeTasks && projectTasks) {
        setProjectTasks(null);
      }
  }, [activeTasks, project]);
  
  useEffect(() => {
    if(projectTasks) {
      const currentActiveApproval = projectTasks.find(pTask => pTask.type_name === 'approval' && !pTask.task_completed);
      if(currentActiveApproval && 
          currentActiveApproval !== projectApprovalTask) {
        setProjectApprovalTask(currentActiveApproval);  
      }else if(!currentActiveApproval && projectApprovalTask) {
        setProjectApprovalTask(null);
      }
    }
  }, [projectTasks]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'current.task_user') &&
        !props.objExists(activeTask, 'user')) {
      setActiveTask({...activeTask, user: props.admin.current.task_user});
    }
  }, [props.admin && props.admin.current]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'persona.prefs') && 
      Array.isArray(props.site.persona.prefs) && props.site.persona.prefs.length > 0) {
        const oProp = props.site.persona.prefs.filter(property => property.property_name === 'hide_pricing');
        if (oProp.length > 0 && oProp[0].property_value == 'true') {
          setHidePricing(true);
        } else {
          setHidePricing(false);
        }
    }
  }, [props]);

  // FUNCTIONS
  function getActiveTask(tasks) {
    if(Array.isArray(tasks) && tasks.length > 0) {
      let stopTask = null;
      
      tasks.map(task => {
        if(!stopTask && !task.task_completed) {
          if(task.subtasks && 
              Array.isArray(task.subtasks) &&
              task.subtasks.length > 0) {
              stopTask = getActiveTask(task.subtasks);
          }else{
            stopTask = task;
          }
        } 
      });
      return stopTask; 
    }else{
      return null;
    }
  }
  
  function handleApprove(value) {
    if(value === true) {
      props.setModal(<FormDialogBox
                        dialog="You are approving this project to move into production"
                        buttons={[{label: 'Cancel', btnClass: 'error-bg', action: null},
                                {label: 'Approve', btnClass: 'success-bg', action: approveProject}, ]}
                        actionClose={()=>{props.setModal(null)}}
                        />);
    }else{
      setProjectApproved(false);
    }
  }

  function approveProject() {
    setProjectApproved(true);
  }

  function printObj(obj) {
    const styles = document.head.querySelectorAll('style');
    const links = document.head.querySelectorAll('link');
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    const printPage = <PrintShell {...props} 
                                  styles={styles} 
                                  links={links}
                                  title={`ORDER ${props.projectNumDisplay(project.project_id, project.organization_code)}`} >
                        <div class="card active">
                          {ReactHtmlParser(obj.innerHTML)}
                        </div>
                      </PrintShell>;
    ReactDOM.render(printPage, WinPrint.document);
    
    WinPrint.document.close();
    WinPrint.focus();
    // WinPrint.print();
    // WinPrint.close();
  }
  
  function createTasks() {
    const sPTasksParams = [
      {name: 'process', value: 'set_project_tasks'},
      {name: 'user_id', value: userID}, 
      {name: 'token', value: token},
      {name: 'project_id', value: project.project_id},
      {name: 'organization_id', value: project.organization_id},
      {name: 'return_tasks', value: true}
    ];
    if(!props.objExists(project, 'tasks') ||
        !Array.isArray(project.tasks) ||
        project.tasks <= 0) {
        props.adminLoadState(sPTasksParams, 'task', 'project.tasks');
        props.adminSetVal(`reload.orders`);
        props.adminSetVal(`reload.project`);
        setTasksLoading(true);
    }
  }
  
  // console.log("PROJECT ADMIN", props.admin);
  // console.log("USER ACCESS", userAccess);

  return (
      project &&
      <article className="project">
        {project && organizationID && languageCode ?
          <Fragment>
            <div className="flex-row" style={{marginLeft: '0em', marginBottom: '2em', justifyContent: 'space-between'}}>
              <h2><IconSVG icon="cart" className="h2" />
                  {
                    `${props.objGetValue(project, 'project_name') === 'Online Order' ?  'ORDER' : 'PROJECT'}${project ?
                    ` ${props.projectNumDisplay(project.project_id, project.organization_code)}`
                  :
                    ''}`
                  }
              </h2>
              {projectApprovalTask &&
                  <TaskInline {...props}
                    userID={userID}
                    token={token}
                    languageCode={languageCode}
                    location='admin'
                    source={'project'}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    userAccess={userAccess}
                    taskOrg={project.organization_id}
                    taskOwnerType={'taskToProject'}
                    taskOwnerID={project.project_id}
                    taskRootType={'taskToProject'}
                    taskRootID={project.project_id}
                    tasks={[projectApprovalTask]} />
              }
            </div>
            
            {props.isAdmin && 
              (userAccess.super || 
                userAccess.staffAdmin[project.organization_id]) &&
                <div className="form-row">
                  {tasksLoading ?
                    <div>
                      <IconSVG icon="task" />
                      Tasks pending...
                    </div>
                  :
                    (!props.objExists(project, 'tasks') || 
                      !Array.isArray(project.tasks) || 
                      project.tasks.length <= 0) ?
                      <Control {...props}
                        icon="task"
                        label="Set Project Tasks"
                        showLabel={true}
                        action={createTasks} />
                    :
                      <Link to={`/admin/orders/order-tasks/${project.project_id}`}>
                        <IconSVG icon="task" />
                        View/assign project tasks...
                      </Link>
                  }
                </div>
            }
            
            <Card>
              <div className="card-block">
                <div className="card-coloumns">
                  <div className="card-block">
                    <div className="title">Account</div>
                  </div>
                </div>
                  
                <div className="card-columns">
                  <div className="card-block">              
                    <div className="field">
                      <label>Project N<sup><u>O</u></sup></label>
                      {project && props.projectNumDisplay(project.project_id, project.organization_code)}
                    </div>
                    
                    {project.project_name &&
                      project.project_name !== 'Online Order' &&
                      <div className="field">
                        <label>Project Name</label>
                        {project.project_name}
                      </div>
                    }
                    
                    <div className="field">
                      <label>User</label>
                      {`${
                          props.objExists(project, 'user.contact_name_first') ?
                            project.user.contact_name_first :
                              props.objExists(project, 'contact.contact_name_first') ?
                              project.contact.contact_name_first : 'First'
                            } ${
                              props.objExists(project, 'user.contact_name_last') ?
                              project.user.contact_name_last :
                                props.objExists(project, 'contact.contact_name_last') ?
                                project.contact.contact_name_last : 'Last'
                            }`
                      }
                    </div>
                    {props.isAdmin &&
                      <Fragment>
                      <div className="field">
                        <IconSVG icon="email" />
                        <div className="field-value">
                          {props.objExists(project, 'user.contact_email') ?
                            <a href={`mailto:${project.user.contact_name_first} ${project.user.contact_name_last}<${project.user.contact_email}>`} external="true">
                              {project.user.contact_email}
                            </a>
                           : 
                            `Unavailable`
                          }
                        </div>
                      </div>
                      
                      {props.objExists(project, 'user.contact_phone') &&
                        <div className="field">
                          <IconSVG icon="phone" />
                          <div className="field-value">
                            <a href={`tel:${project.user.contact_phone}`}>
                              {project.user.contact_phone}
                            </a>
                          </div>
                        </div>
                      }
                      
                      {props.objExists(project, 'user.contact_fax') &&
                        <div className="field">
                          <IconSVG icon="fax" />
                          <div className="field-value">
                            {project.user.contact_fax}
                          </div>
                        </div>
                      }
                      
                      {props.objExists(project, 'user.contact_mobile') &&
                        <div className="field">
                          <IconSVG icon="mobile" />
                          <div className="field-value">
                            <a href={`tel:${project.user.contact_mobile}`}>
                              {project.user.contact_mobile}
                            </a>
                          </div>
                        </div>
                      }
                      
                      </Fragment>
                      
                    }
                    
                  </div>
              
                  <div className="card-block">
                    {/* <div className="field">
                      <label>Status</label>
                      {project.status ?
                        props.capitalizeString(status, true) :
                        'Unavailable'}
                    </div> */}
                    
                    {
                      project.status &&
                      Array.isArray(project.status) &&
                      project.status.length > 0 &&
                      project.status.map((status, index) => {
                        if (index < (project.status.length-1)) {
                          return (
                            <div key={`prjts${index}`} className="field">
                              <label>
                                {status[languageCode] && status[languageCode].status_name ?
                                  props.capitalizeString(status[languageCode].status_name, true) :
                                  'Unavailable'}
                              </label>
                              {props.objExists(status, 'status_modified') && `${props.dateDisplay(status.status_modified)} ${props.timeDisplay(status.status_modified, false)}`}
                            </div>
                          )
                        }
                      })
                    }
                    
                    <div className="field">
                      <label>Placed</label>
                      {props.objExists(project, 'project_created') && `${props.dateDisplay(project.project_created)} ${props.timeDisplay(project.project_created, false)}`}
                    </div>
                    
                    {props.objGetValue(project, 'project_created') < props.objGetValue(project, 'project_modified') &&
                    <div className="field">
                      <label>Updated</label>
                      {props.objExists(project, 'project_modified') && `${props.dateDisplay(project.project_modified)} ${props.timeDisplay(project.project_modified, false)}`}
                    </div>
                     }
                  </div>
                </div>
              </div>
            </Card>
            {project.orders &&
              Array.isArray(project.orders) &&
              project.orders.length > 0 &&
              project.orders.map((order, index) => {
                return <ProjectOrder  key={`prjto${index}`}
                                      {...props}
                                      userID={userID}
                                      token={token}
                                      organizationID={organizationID}
                                      languageCode={languageCode}
                                      userAccess={userAccess}
                                      location={props.location}
                                      isAdmin={props.isAdmin}
                                      index={index}
                                      projectID={project.project_id}
                                      coreOrgCode={project.organization_code}
                                      coreOrg={project.organization_id}
                                      coreUser={project.user_id}
                                      order={order}
                                      multiple={project.orders.length > 1 ? true : false}
                                      projectApproved={projectApproved}
                                      orderActions={{printOrder: printObj}}
                                      tasks={activeTasks}
                                      taskSource={'project'}
                                      projectStatus={status}
                                      hidePricing={hidePricing} />
              })
            }
            {props.objGetValue(props.site, 'preferences.hide_pricing') !== true && parseFloat(props.objGetValue(props.project, 'project_total')) > 0 && 
              <Card>
                <div className="card-block">
                  <div className="title margin-bottom-0em">Financials</div>
                  <ProjectFinancials {...props} 
                    project={project} 
                    userID={userID}
                    token={token}
                    languageCode={languageCode}
                    userAccess={userAccess}
                    isAdmin={props.isAdmin} 
                    financialType="project"
                    projectStatus={status} />
                </div>
              </Card>
            }
          </Fragment>
        :
         <Loader message="Loading project...." overlayContents={true} /> 
        }
      </article>
  )
}
