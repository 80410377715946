import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router';

import Header from 'components/header/Header';
import Footer from 'components/organisms/Footer';
import Modal from 'components/organisms/Modal';
import Notification from 'components/organisms/Notification';
import Loader from 'components/atoms/Loader';

import * as actions from 'actions/';
import * as utils from 'methods/site';

const PagePublic = (props) => {
  props = {...props, ...utils}
  const [tokenCheck, setTokenCheck] = useState(null);
  const [loginTest, setLoginTest] = useState(null);
  const [userRequested, setUserRequested] = useState(null);
  const [siteNotice, setSiteNotice] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [organizationID, setOrganizationID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [orgUTSet, setOrgUTSet] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  // const [siteHeader, setSiteHeader] = useState(null);
  
  let ivlCategories;
  let ivlProducts;
  let ivlUserTasks;
  
  // HOOKS
  useEffect(() => {
    return () => {
      ivlCategories &&
      clearInterval(ivlCategories);
      
      ivlProducts &&
      clearInterval(ivlProducts);
      
      ivlUserTasks &&
      clearInterval(ivlUserTasks);
    }
  }, []);
  
  useEffect(() => {
    const paramsPersona = [
      {name: 'process', value: 'get_site_persona_db'},
      {name: 'host_name', value: window.location.hostname},
      {name: 'port_num', value: window.location.port ? window.location.port : window.location.protocol === 'https:' ? 443 : 80},
      {name: 'user_id', value: userID},
      {name: 'token', value: token}
    ];
    
    if(!props.objExists(props.site, `persona`) || 
        props.objGetValue(props.site, `reload.persona`) === true) {
          // console.log("PERSONA PARAMS", paramsPersona);
        props.siteLoadState(paramsPersona, 'site', 'persona');
        if(props.objGetValue(props.site, `reload.persona`) === true) {
          props.siteRemoveValue(`reload.persona`);
          
        }
    }
  }, [props.site, userID, token]);
  
  // BASED ON SITE.PERSONA STATE
  useEffect(() => {
    if(utils.objExists(props.site, `persona.organization_id`)) {
        // SET ORG ID IF NOT SET OR SAME
        if(props.site.persona.organization_id !== organizationID) {
          setOrganizationID(props.site.persona.organization_id);  
        }
        
        // SET PUBLIC ACCESS TO PORTAL
        if(props.objGetValue(props.site, 'persona.organization_public') === 1 &&
            !isPublic) {
          setIsPublic(true);
        }
        
        // IF SITE PERSONA HAS PREFERNECES SET:
        // EXTRACT THEM AND SET THEM INTO STATE FOR EASY ACCESS
        if(utils.objExists(props.site, `persona.prefs`) && 
          Array.isArray(props.site.persona.prefs)) {
        let site_prefs = {};
        props.site.persona.prefs.map(pref => {
            site_prefs[pref.property_name] = pref.property_value === 'true' ? true :
                                                pref.property_value === 'false' ? false :
                                                  pref.property_value ;
          })
          if(!utils.objExists(props.site, `preferences`) || 
              props.site.preferences !== site_prefs) {
              props.siteSetValue('preferences', site_prefs); 
          }
        }
       
        // WILL RELOAD SITE PERSONA AFTER 5 MINUTES
        let timerPersona = setTimeout(()=> {
          props.initSite(window.location); 
        }, 300000);
       
        return () => {
          clearTimeout(timerPersona);
        }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.site.persona, userID, token]);
  
  useEffect(() => {
    if(userID && token && !orgUTSet) {
      props.siteSetValue('reload.persona', true);
    }
  }, [userID, token]);
  
  // SETTING SITE LANGUAGE - ALLOW FOR SITE TO CHANGE LANGUAGE
  useEffect(() => {
    if(organizationID) {
      if (props.objExists(props.site, 'site_language') && !languageCode) {
        setLanguageCode(props.site.site_language);
      }else if(!languageCode && !props.site.site_language) {
        let defaultLanguage = 'en';
        if(props.objExists(props.site, `persona.languages`) && 
          Array.isArray(props.site.persona.languages) && 
          props.site.persona.languages.length > 0) {
          let defaultLanguageObj = props.site.persona.languages.find(l => l.language_org_default === 1);
          if(defaultLanguageObj) {
            defaultLanguage = defaultLanguageObj['language_code'];
          }else{
            defaultLanguage = props.site.persona.languages[0].language_code;
          }
        }
        props.siteSetValue('site_language', defaultLanguage);
        setLanguageCode(defaultLanguage);
      }else if(languageCode && languageCode != props.site.site_language) {
        setLanguageCode(props.site.site_language);
      }
    }
  }, [organizationID, props.site.site_language]);
  
  // ONCE ORG ID AND LANGUAGE CODE ARE SET
  useEffect(() => {
    if(organizationID && languageCode && (isPublic || (userID && token))) {
      const paramsCategories = [
        {name: 'process', value: 'get_category_list'},
        {name: 'organization_id', value: organizationID},
        {name: 'language_code', value: languageCode},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'category_type', value: 'product'}
      ];
      
      if(!props.objExists(props.store, `categories`) ||
          props.objGetValue(props.store, 'reload.categories') === true ||
          (props.objExists(props.store, `categories`) &&
            props.objGetValue(props.store, 'current.request.categories_no_user') === true && 
            userID)) {
        props.storeLoadState(paramsCategories, 'store', 'categories');
        props.storeSetVal('current.request.get_categories', true);
        if(!userID) {
          props.storeSetVal('current.request.categories_no_user', true)  
        }else if(userID && props.objGetValue(props.store, 'request.categories_no_user') === true){
          props.storeDeleteVal('current.request.categoried_no_user');
        }
        
        ivlCategories &&
        clearInterval(ivlCategories);
        
        ivlCategories = setInterval(()=> {
          if(document.visibilityState === 'visible') {
            props.storeLoadState(paramsCategories, 'store', 'categories');
          }
        }, props.minToMS(30));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, languageCode, props.store.reload, userID, token, isPublic]);
  
  useEffect(() => {
    if(organizationID && languageCode) {
      const productParams = [
        {name: 'process', value: 'get_product_list'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'language_code', value: languageCode}
      ]
      
      if((!props.objExists(props.store, 'products') ||
        props.objGetValue(props.store, `reload.products`) === true) &&
        (isPublic || (userID && token))) {
        
        props.storeLoadState(productParams, 'store', 'products');
        props.objGetValue(props.store, `reload.products`) === true &&
        props.storeDeleteVal('reload.products');
        
        ivlProducts &&
        clearInterval(ivlProducts);
        
        ivlProducts = setInterval(()=>{
          if(document.visibilityState === 'visible') {
            props.storeLoadState(productParams, 'store', 'products');
          }
        }, props.minToMS(30));
        
      }
    }
  }, [organizationID, languageCode, props.store.reload, userID, token, isPublic]);

  // PERFORM LOGIN TEST: CHECK FOR USER ID > IF NO:
  // CHECK FOR SESSION OR LOCAL TOKEN AND IF PRESENT
  // ATTEMPT TO LOAD FROM TOKEN > WILL RETURN TO CHECK
  // UNLESS LOGIN TEST IS COMPLETE (TRUE)
  useEffect(() => {
    if(!loginTest && !window.location.pathname.includes('/login') && organizationID) {
      if(!utils.objExists(props.user, 'user_id')) {
          
        if(sessionStorage.getItem('token') && 
            setTokenCheck !== 'session' && !userRequested && setTokenCheck !== 'local') {
          const sessionParams = [
            {name: 'process', value: 'get_user_from_token'},
            {name: 'token', value: sessionStorage.getItem('token')},
            {name: 'organization_id', value: organizationID}
          ];
          props.userLoadState(sessionParams, 'users', null);
          
          setUserRequested(true);
          setTokenCheck('session');
        }else if (localStorage.getItem('token') && !userRequested && setTokenCheck !== 'local') {
          const localParams = [
            {name: 'process', value: 'get_user_from_token'},
            {name: 'token', value: localStorage.getItem('token')},
            {name: 'organization_id', value: organizationID}
          ]
          props.userLoadState(localParams, 'users', null);
          
          setUserRequested(true);
          setTokenCheck('token');
        }else if(userRequested) {
          setUserRequested(false);
        }else{
          setTokenCheck(null);
          setLoginTest(true);
        }
      }else if(!loginTest && props.objExists(props.user, 'user_id')) {
        setUserRequested(false);
        setTokenCheck(null);
        setLoginTest(true);
      }  
    }else if(props.objExists(props.user, 'user_id')){
      if(props.objExists(props.user, 'userLoggedOut')) {
        props.userDeleteVal('userLoggedOut');
      }
      setUserRequested(false);
      setTokenCheck(null);
      setLoginTest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, organizationID, loginTest]);
  
  
  // IF LOGIN TEST COMPLETE:
  useEffect(() => {
    if(loginTest) {
      if(props.objExists(props.user, 'user_id')){
        if(props.user.user_id !== userID) {
          props.siteSetValue('reload.modules', true);
          setUserID(props.user.user_id);
        }
        
        if(props.user !== user) {
          setUser(props.user);
        }
        
        if(props.user.token && props.user.token !== token) {
          setToken(props.user.token);
        }
        
      }else{
        if(props.objGetValue(props.site, 'userUpdate') !== true && !isPublic ) {
          !window.location.pathname.match('/login') &&
          props.siteSetValue('navigation.referrer', window.location.pathname);
          setRedirect('/login');  
        }
      }  
    }
  }, [loginTest, props.user, props.site.userUpdate]);
  
  useEffect(() => {
    if(userID) {
      if(sessionStorage.getItem('token') && sessionStorage.getItem('token') !== token) {
        setToken(sessionStorage.getItem('token'));
      }else if(localStorage.getItem('token')  && localStorage.getItem('token') !== token){
        setToken(localStorage.getItem('token'));
      }
    }
  }, [userID]);
  
  useEffect(() => {
    if(userID && token){
      const taskParams = [
        {name: 'process', value: 'get_user_tasks'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'task_completed', value: false},
        {name: 'task_published', value: true}
      ];
      if(!props.objExists(props.user, `tasks`) ||
          props.objGetValue(props.user, `reload.tasks`) === true) {
            props.userLoadState(taskParams, 'tasks', 'tasks');
            if(props.objGetValue(props.user, `reload.tasks`) === true) {
              props.userRemoveVal('reload.tasks');
            }
            if(ivlUserTasks) {
              clearTimeout(ivlUserTasks);
            }
            ivlUserTasks = setInterval(() => {
              if(document.visibilityState === 'visible') {
                props.userLoadState(taskParams, 'tasks', 'tasks');
              }
            }, props.minToMS(5));
          }
    }
  }, [props.user, token, organizationID]);
  
  // useEffect(() => {
  //   if(organizationID) {
  //     setSiteHeader(
  //       <Header {...props}
  //         userID={userID}
  //         siteUser={user}
  //         token={token}
  //         organizationID={organizationID}
  //         languageCode={languageCode} /> 
  //     );
  //   }
  // }, [userID, token, organizationID, isPublic, languageCode, props.site, props.cart, props.user]);

  let childrenWithProps = [];
  React.Children.forEach(props.children, (child, index)=> {
    childrenWithProps.push(React.cloneElement(child, {key: index}, {...props} ));
  })
  
  // console.log("PROPS SITE", props.site);
  
  return(
    <Fragment>
      {redirect && <Redirect to={redirect} />}
      <Modal {...props} />
      {organizationID &&
        <Header {...props}
          userID={userID}
          siteUser={user}
          token={token}
          organizationID={organizationID}
          languageCode={languageCode} />
      }
      {siteNotice ?
          <main><p>Site Unavailable</p></main>
          :
          utils.objExists(props.site, 'persona') ?
            <main>{childrenWithProps}</main>
          : <main><Loader /></main>
        }
      <Footer  {...props}
                userID={userID}
                token={token}
                organizationID={organizationID}
                languageCode={languageCode} />
      <Notification {...props} {...props.site.notification} actionRemove={props.removeNotification} />
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    reqUrl: state.user.reqUrl,
    modal: state.modal.modal,
    site: state.site,
    cart: state.cart,
    form: state.form,
    files: state.files,
    store: state.store,
  };
}

export default connect(mapStateToProps, actions)(PagePublic);
