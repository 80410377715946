import React, { useEffect, useState, Fragment } from 'react';
import { useParams, useLocation } from "react-router";

import Breadcrumb from 'components/navigation/Breadcrumb';
import MediaSet from 'components/profiles/media/MediaSet';
import ProductDetails from 'components/products/ProductDetails';
import ProductChildren from 'components/products/ProductChildren';
import ProductLivePreview from 'components/products/ProductLivePreview';
import Loader from 'components/atoms/Loader';

import * as utils from 'methods/site';

// "react-router-dom": "^4.2.2",

export default (props) => {
  props = {...props.children, ...utils};

  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [product, setProduct] = useState(null);
  const [restriction, setRestriction] = useState(null);
  const [reloadProduct, setReloadProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [media, setMedia] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [subProduct, setSubProduct] = useState(null);
  const [bulkSelect, setBulkSelect] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  const [useModal, setUseModal] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [isShell, setIsShell] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [productApproval, setProductApproval] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [optionalProperties, setOptionalProperties] = useState(null);
  
  let { product_id } = useParams();
  const pgLocation = useLocation();
  
  // HOOKS
  useEffect(() => {
    return() => {
      props.storeDeleteVal('product');
      props.filesClearUploads();
      sessionStorage.removeItem('strPrd');
      // setProduct(null);
      setRestriction(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(props.user, `user_id`)) {
      if(props.user.user_id !== userID) {
        setUserID(props.user.user_id);
      }
      
      if(sessionStorage.getItem('token') && 
        sessionStorage.getItem('token') !== token) {
          setToken(sessionStorage.getItem('token'));
      }else if(localStorage.getItem('token') && 
                localStorage.getItem('token') !== token){
          setToken(localStorage.getItem('token'));
      }
    }
  }, [props.user]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
    }
    if(props.objExists(props.site, 'site_language')) {
      setLanguageCode(props.site.site_language);
    }else{
      setLanguageCode('en');  
    }
    
    if(props.objExists(props.site, `permissions`)) {
      if(props.site.permissions.hidePricing === true && hidePricing !== true) {
        setHidePricing(true);
      }
      if(props.site.permissions.useModal === false && useModal !== false) {
        setUseModal(false);
      }else{
        setUseModal(true);
      }
    }
  }, [props.site]);
  
 useEffect(() => {
    if(languageCode && product_id && organizationID) {
      const paramsProduct = [
        {name: 'process', value: 'get_product'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'language_code', value: languageCode},
        {name: 'product_id', value: product_id}
      ]
      // props.getProduct(product_id, languageCode, organizationID);
      props.storeLoadState(paramsProduct, 'store', 'product');
      if(reloadProduct){
        setReloadProduct(null);
      }
    }
    
    if(userID && organizationID && product_id) {
      const paramsRest = [
        {name: 'process', value: 'get_user_product_restriction'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'product_id', value: product_id}
      ];
      
      props.storeLoadState(paramsRest, 'products', 'restriction');
    }
  }, [product_id, languageCode, organizationID, userID, token, reloadProduct]);
  
  
  
  useEffect(() => {
    if(pgLocation.search && props.objExists(props.store, `categories`) && 
      languageCode && product) {
      const pgSearch = props.getURLSearchParams(pgLocation.search);
      if(parseInt(pgSearch.cat) > 0 ) {
        const referrerCat = props.getCategory(parseInt(pgSearch.cat), 
                                              props.store.categories, 
                                              [], 
                                              languageCode);
        if(Array.isArray(props.objGetValue(referrerCat, `category_breadcrumb`)) &&
            referrerCat.category_breadcrumb !== breadcrumb) {
          setBreadcrumb([...referrerCat.category_breadcrumb, 
                          {
                            breadcrumb_name: props.objExists(product.details, `${languageCode}.product_name`) ? product.details[languageCode].product_name : '',
                            breadcrumb_value: `/product/${product.product_id}${pgLocation.search}`
                          }
          ]);
        }
      }
    }else if(product && languageCode){
      setBreadcrumb([
        {
          breadcrumb_name: `categories`,
          breadcrumb_value: `/categories`
        },
        {
          breadcrumb_name: props.objExists(product.details, `${languageCode}.product_name`) ? product.details[languageCode].product_name : '',
          breadcrumb_value: `/product/${product.product_id}${pgLocation.search ? pgLocation.search : ''}`
        }
      ]);
    }
  }, [pgLocation, props.store.categories, product, languageCode]);
  
  useEffect(() => {
    if(props.store.product) {
      setProduct(props.store.product);
      if(props.store.product !== product) {
        
      }
      
      if(sessionStorage.getItem('strPrd') !== JSON.stringify(props.store.product)) {
        sessionStorage.setItem('strPrd', JSON.stringify(props.store.product));
      }
    }
  }, [props.store.product]);
  
  useEffect(() => {
    if(Object.keys(props.objGetValue(props.store, `restriction`)).length > 0 && 
        props.objExists(props.store, `restriction.restriction_id`)) {
          setRestriction(props.store.restriction);
        }
  }, [props.store.restriction]);
  
  useEffect(() => {
    if(props.objGetValue(product, `details.${languageCode}.shell.shell_published`) === 1 &&
      !isShell) {
        setIsShell(true);
    }
    
    if(props.objExists(product, `approval`) && 
        product.approval !== productApproval) {
      setProductApproval(product.approval);
    }
    
    if(props.objExists(product, `properties`) &&
        product.properties !== optionalProperties) {
      setOptionalProperties(product.properties);
    }
  }, [product]);
  
  useEffect(() => {
    if(props.objExists(props.form, `tmpFormData.productFieldValues`) &&
      props.form.tmpFormData.productFieldValues !== formValues) {
        setFormValues(props.form.tmpFormData.productFieldValues);
      }
  }, [props.form.tmpFormData]);
  
  useEffect(() => {
   if(isShell && formValues) {
    setPreviewURL(`https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&product_proof=1&product_id=${props.objExists(product, 'product_id') ? product.product_id : 0}&field_values=${encodeURIComponent(JSON.stringify(formValues))}&box_model=TrimBox&target=I&preview_mode=false`)}`);
   }
  }, [isShell, formValues, product])
  
  useEffect(() => {
    if(products && 
        (!props.objExists(props.store, `product.selected_products`) || 
      !Array.isArray(props.store.product.selected_products))){
        props.storeSetVal('product.selected_products', products);
    }
  }, [products, product]);
  
  useEffect(() => {
    if(languageCode && product && selectedProducts) {
      let prodPrice = 0.00;
      if(Array.isArray(props.objGetValue(product, `details.${languageCode}.pricing`)) &&
        product.details[languageCode].pricing.length > 0) {
          
      }
      if(props.objExists(product, `details.${languageCode}.charges.charge_value`) &&
        !isNaN(parseFloat(product.details[languageCode].charges.charge_value))) {
          prodPrice += parseFloat(product.details[languageCode].charges.charge_value);
      }
    }
  }, [product, selectedProducts, languageCode]);

  useEffect(() => {
    if(languageCode && product) {
      if(props.objExists(product, `details.${languageCode}`) &&
        product.details[languageCode] !== productDetails) {
        setProductDetails(product.details[languageCode]);
      }
      
      if(props.objExists(product, `details.${languageCode}.media`) &&
        Array.isArray(product.details[languageCode].media) &&
        product.details[languageCode].media.length > 0 &&
        product.details[languageCode].media !== media) {
        setMedia(product.details[languageCode].media);
      }
      
      if(Array.isArray(props.objGetValue(product, `details.${languageCode}.products`)) && 
          product.details[languageCode].products !== products) {
          setProducts(product.details[languageCode].products);
      }
      if(Array.isArray(props.objGetValue(product, `selected_products`)) && 
          product.selected_products !== selectedProducts) {
          setSelectedProducts(product.selected_products);
      }
    }
  }, [languageCode, product, subProduct, bulkSelect]);
  
  useEffect(() => {
    if(props.objExists(product, `selected_products`)) {
      setSelectedProducts(product.selected_products);
    }
  }, [product && product.selected_products]);
  
  useEffect(() => {
    if(organizationID) {
      document.title = `${props.site.persona.organization_name} : ${props.objExists(product, `details.${languageCode}.product_name`) ? props.capitalizeString(product.details[languageCode].product_name, true) : 'Product'}`;
    }
  }, [organizationID, languageCode, product, props.store.persona]);
  
  useEffect(() => {
    if(product && subProduct) {
      let productsSelected = Array.isArray(selectedProducts) ? 
                              product.selected_products : [];
        
      if(subProduct.value === false && Array.isArray(productsSelected)) {
        productsSelected = productsSelected.filter(prod => parseInt(prod.product_id) !== parseInt(subProduct.product_id));
      }else if(subProduct.value === true && 
                Array.isArray(productsSelected) &&
                !productsSelected.find(prod => prod.product_id === subProduct.product_id)) {
        if(Array.isArray(products)) {
          const newProduct = products.find(prod => prod.product_id === subProduct.product_id);
          if(newProduct) {
            productsSelected.push(newProduct);
            // selectedProducts.sort((a, b) => {return a.product_order > b.product_order ? 1 : -1});
          }
        } 
      }
      
      if(props.objGetValue(props.store, 'product.selected_products') !== productsSelected) {
        props.storeSetVal('product.selected_products', productsSelected);
        // setSelectedProducts(productsSelected);
      }
      setSubProduct(null);
    }
  }, [subProduct, product, languageCode, selectedProducts, products]);
  
  useEffect(() => {
    if (props.objExists(product, `details.${languageCode}.product_page_count`) || (selectedProducts && Array.isArray(selectedProducts))) {
      let nPageCount = 0;
      if(props.objExists(product, `details.${languageCode}.product_page_count`) && product.details[languageCode].product_page_count > 0) {
        nPageCount = product.details[languageCode].product_page_count;
      }
      if (selectedProducts && Array.isArray(selectedProducts)) {
        selectedProducts.map((product, index) => {
          nPageCount += product.product_page_count;
        })
      } else if (props.objExists(product, `details.${languageCode}.sub_products_page_count`) && product.details[languageCode].sub_products_page_count > 0) {
        nPageCount += product.details[languageCode].sub_products_page_count;
      }
      setPageCount(nPageCount);
    } else {
      setPageCount(null);
    }
  }, [subProduct, selectedProducts]);
  
  useEffect(() => {
    // props.store.product.details[languageCode].products.filter(prod => parseInt(prod.product_optional) === 0)
    if(bulkSelect === 'all' && 
        props.objExists(props.store, `product.details.${languageCode}.products`) &&
        Array.isArray(props.store.product.details[languageCode].products)) {
      props.storeSetVal('product.selected_products', props.store.product.details[languageCode].products);
      setBulkSelect(null);
    }else if(bulkSelect === 'none') {
      props.storeSetVal('product.selected_products', props.store.product.details[languageCode].products.filter(prod => parseInt(prod.product_optional) === 0));
      setBulkSelect(null);
    }
  }, [bulkSelect]);
  
  // FUNCTIONS
  function selectProducts(product, value) {
    setSubProduct(product);
  }
  
  function bulkSelectProducts(range = null) {
    setBulkSelect(range);
  }
  
  function handleProductApproval(approval) {
    if(approval.approval_status) {
      const approvalDate = new Date();
      approval = {
        ...approval,
        approval_date: props.dateDisplay(approvalDate, 'en', 'mysql', true),
        approval_user_id: userID
      }
      props.storeSetVal(`product.approval`, approval);
    }
  }
  
  function handleClearProductApproval() {
    
  }
  
  function handleCartAdd() {
    setProduct(null);
    setReloadProduct(true);
    sessionStorage.removeItem('strPrd');
    props.filesClearUploads();
  }

  return (
    <article className="product">
      {breadcrumb &&
        <Breadcrumb {...props} breadcrumb={breadcrumb} />  
      }
      {isShell &&
        <div>
          <a href={previewURL ? previewURL : '#'} target="_blank">
            This is an On-Demand Product
          </a>
        </div>
      }
      {props.objGetValue(product, 'product_id') === parseInt(product_id) ?
      <Fragment>
        <h2>{productDetails && productDetails.type.type_name ? utils.capitalizeString(productDetails.type.type_name, true) : 'Product Details'}</h2>
        <div className="product-info">
          {languageCode && product && 
            props.objExists(product, `details.${languageCode}.products`) && 
            Array.isArray(product.details[languageCode].products) &&
            product.details[languageCode].products.length > 0 ?
            <ProductChildren {...props}
                              products={product.details[languageCode].products}
                              selectedProducts={selectedProducts}
                              optionalProperties={optionalProperties}
                              actionSelect={selectProducts}
                              actionBulkSelect={bulkSelectProducts} />
          :
          
            isShell && previewURL && formValues ?
              <ProductLivePreview 
                {...props}
                previewURL={previewURL}
                actionApproval={handleProductApproval}
                actionClearApproval={handleClearProductApproval}
                approval={productApproval} />
            :
              media &&
              <MediaSet  {...utils} 
                          media={media} 
                          languageCode={languageCode}
                          setType='images' />
          }
          
          <ProductDetails  {...props} {...utils} 
              product={product}
              restriction={restriction}
              handleCartAdd={handleCartAdd}
              pageCount={pageCount}
              languageCode={languageCode}
              isShell={isShell}
              productApproval={product.approval ? product.approval : null}
              hidePricing={hidePricing === true || props.objGetValue(props.site, `preferences.hide_pricing`) === true ?
                            true : false }
              useModal={useModal === false || 
                        (props.objExists(props.site, `preferences.useModal`) && 
                          props.objGetValue(props.site, `preferences.useModal`) === false) ? 
                            false : true }
              actionSelectProducts={selectProducts}
              actionBulkSelectProducts={bulkSelectProducts} />
        </div>
      </Fragment>
      :
        <Loader />
      }
    </article>
  )
}
