import React, {useEffect, useState, Fragment} from 'react';

import MediaHero from 'components/profiles/media/MediaHero';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';

export default (props) => {
	const [lowStock, setLowStock] = useState(null);
	const [lowDaysAvailable, setLowDaysAvailable] = useState(null);
	const [isUnpublished, setIsUnpublished] = useState(null);
	const [itemClass, setItemClass] = useState(null);
	const [link, setLink] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [product, setProduct] = useState(null);
	const [linkProduct, setLinkProduct] = useState(null);
	const [mediaFileName, setMediaFileName] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return() => {
			setProduct(null);
		}	
	}, []);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.product && props.product !== product) {
			setProduct(props.product);
		}
	}, [props.product]);
	
	useEffect(() => {
		if(props.objExists(product, `${languageCode}.product_image`)) {
			let productImage = product[languageCode].product_image.split('/')[3];
			setMediaFileName(productImage);
		}
	}, [product]);
	
	useEffect(() => {
		if(props.site.modules) {
			const modulesAdmin = props.site.modules.find(mod => mod.module_component === 'Admin');
			const modulesAdminProduct = modulesAdmin.modules.find(mod => mod.module_component === 'AdminProduct');
			const modulesAdminProductList = modulesAdminProduct.modules.find(mod => mod.module_component === 'AdminProductList');
			if(modulesAdminProductList) {
				setLinkProduct(true);
			}
		}
	}, [props.site.modules]);
	
	useEffect(() => {
		if(product) {
			if(product.product_stock < product.product_stock_min) {
				setLowStock(true);
			}else{
				setLowStock(false);
			}
			
			if(product.product_days_available && product.product_days_available <= 10) {
				setLowDaysAvailable(true);
			}else{
				setLowDaysAvailable(false);
			}
			
			if(product.product_published === 0) {
				setIsUnpublished(true);
			}else{
				setIsUnpublished(false);
			}
			if(props.objExists(product, 'product_id')) {
				setLink(`/${props.location ? props.location : 'admin'}/products/product/${product.product_id}`);
			}
		}
	}, [product]);
	
	useEffect(() => {
		let className = '';
		if(lowStock) {
			className += " error-text";
		}
		if(lowDaysAvailable) {
			className += " caution";
		}
		if(isUnpublished) {
			className += " unpublished";
		}
		setItemClass(className);
	}, [lowStock, lowDaysAvailable, isUnpublished]);
	
	function productEndLabel(product_end) {
		const dateProductEnd = new Date(product_end);
		const dateToday = new Date();
		if (dateProductEnd > dateToday) {
			return " (Expires: ";
		} else {
			return " (Expired: ";
		}
	}	
	
	return (
		product && languageCode &&
		<Fragment>
			<div>
				{props.objExists(product, `${languageCode}.product_image`) &&
					<MediaHero {...props} 
								mediaFileName={mediaFileName}
								className="grid-item-media"
								isAdmin={false} />
				}
			</div>
			<div className={`${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						<b>{product[languageCode] && props.objExists(product, 'product_end') ? props.capitalizeString(product[languageCode].product_name, true) + productEndLabel(product.product_end) + props.dateDisplay(product.product_end) + ')' : product[languageCode] && props.capitalizeString(product[languageCode].product_name, true)}</b><br />
						{product[languageCode] && product[languageCode].product_sku}
					</Link>
				:
					<div>
						<b>{props.objExists(product, 'product_end') ? props.capitalizeString(product[languageCode].product_name, true) + productEndLabel(product.product_end) + props.dateDisplay(product.product_end) + ')' : props.capitalizeString(product[languageCode] && product[languageCode].product_name, true)}</b><br />
						{product[languageCode] && product[languageCode].product_sku}
					</div>
				}
			</div>
			<div className={`text-center${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						<IconSVG icon={`${isUnpublished ? 'hide' : 'eye'}`} tooltip={`${isUnpublished ? 'Hidden' : 'Published'}`} />
					</Link>
				:
					<IconSVG icon={`${isUnpublished ? 'hide' : 'eye'}`} tooltip={`${isUnpublished ? 'Hidden' : 'Published'}`} />
				}
			</div>
			<div className={`text-center${lowDaysAvailable ? itemClass : ''}${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						{product.product_days_available}
					</Link>
				:
					<div>{product.product_days_available}</div>
				}
			</div>
			<div className={`text-center${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						{product.product_days_ordered}
					</Link>
				:
					<div>{product.product_days_ordered}</div>
				}
			</div>
			<div className={`text-center${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						{product.product_bundle}
					</Link>
				:
					<div>{product.product_bundle}</div>
				}
			</div>
			<div className={`text-center${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						{product.product_stock_min}
					</Link>
				:
					product.product_stock_min > 0 && <div>{product.product_stock_min}</div>
				}
			</div>
			<div className={`text-center${lowStock ? itemClass : ''}${isUnpublished ? ' unpublished' : ''}`}>
				{linkProduct ?
					<Link to={link ? link : ''}>
						{product.product_stock > 0 ?
							product.product_stock : "0"
						}
					</Link>
				:
					<div>
						{product.product_stock > 0 ?
							product.product_stock : "0"
						}
					</div>
				}
			</div>
		</Fragment>
	)
}