import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import * as utils from 'methods/site';

import SlideShow from 'components/molecules/SlideShow';
import ArticleSet from 'components/molecules/ArticleSet';
import FeatureCollection from 'components/molecules/FeatureCollection';

export default (props) => {
  props = {...props.children, ...utils};
  const [languageCode, setLanguageCode] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [redirect, setRedirect] = useState(null);
  
  useEffect(() => {
    if(props.site.site_language) {
      setLanguageCode(props.site.site_language);
    }
    
    if(props.objExists(props.site, 'persona.organization_id') &&
        props.site.persona.organization_id !== organizationID) {
      setOrganizationID(props.site.persona.organization_id);  
    }
    
    if(props.objExists(props.site, 'preferences.home_page') && 
        props.site.preferences.home_page !== '' &&
        props.site.preferences.home_page !== 'store_home' &&
        props.site.preferences.home_page !== 'public_home') {
          setRedirect(props.site.preferences.home_page);
        }
  }, [props.site]);
  
  useEffect(() => {
    if(organizationID) {
      let homeComponents = false;
      
      if(props.objExists(props.site, 'persona.slideshows') &&
        Array.isArray(props.site.persona.slideshows) &&
        props.site.persona.slideshows.length > 0) {
          homeComponents = true;
      }
      
      if(props.objExists(props.site, 'persona.features') &&
        Array.isArray(props.site.persona.features) &&
        props.site.persona.features.length > 0) {
          homeComponents = true;
      }
      
      if(props.objExists(props.site, 'persona.articles') &&
        Array.isArray(props.site.persona.articles) &&
        props.site.persona.articles.length > 0) {
          homeComponents = true;
      }
      
      if(!homeComponents) {
        setRedirect('/categories');
      }
      
    }
  }, [organizationID]);
  
  useEffect(() => {
    if(organizationID) {
      document.title = `${props.site.persona.organization_name} : Home`;
    }
  }, [organizationID, props.store.persona]);
  
  return (
    <Fragment>
      {redirect && <Redirect to={redirect} />}
      {languageCode && props.objExists(props.site, `persona.slideshows[0].slides`) &&
        Array.isArray(props.site.persona.slideshows[0].slides[languageCode]) &&
        props.site.persona.slideshows[0].slides[languageCode].length > 0 &&
        <SlideShow {...props}
          slides={props.site.persona.slideshows[0].slides[languageCode]}
          aspectRatio={props.site.persona.slideshows[0].slideshow_aspect_ratio ? 
                        props.site.persona.slideshows[0].slideshow_aspect_ratio : '16:9'} />
      }
      <div className="main-content">
        {Array.isArray(props.objGetValue(props.site, `persona.articles`)) &&
          props.site.persona.articles.length > 0 &&
          <ArticleSet {...props} 
              articles={props.site.persona.articles}
              languageCode={languageCode} />
        }

        {props.objExists(props.site, 'persona.features') &&
        Array.isArray(props.site.persona.features) &&
        props.site.persona.features.length > 0 &&
        <FeatureCollection
            {...props} 
            features={props.site.persona.features}
            languageCode={languageCode} />}
      </div>
    </Fragment>
  )
}
