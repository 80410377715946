import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import TaskInterface from 'components/profiles/tasks/TaskInterface';
import Loader from 'components/atoms/Loader';
import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';

import * as utils from 'methods/site';

export default (props) => {
	props = {...props.children, ...utils};
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [noTask, setNoTask] = useState(null);
	const [taskLoading, setTaskLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [item, setItem] = useState(null);
	const [itemLoading, setItemLoading] = useState(false);
	const [project, setProject] = useState(null);
	const [projectLoading, setProjectLoading] = useState(null);
	
	const pgParams = useParams();
	const location = useLocation();
	
	let intervalTask;
	
	useEffect(() => {
		return () => {
			props.userRemoveVal('task');
			props.userRemoveVal('project');
			props.userRemoveVal('item');
			setTask(null);
			setItem(null);
			setProject(null);
			props.filesDeleteVal('uploadComplete');
			props.filesDeleteVal('uploads');
		}
	}, [])
	
	useEffect(() => {
		if(props.objExists(props.site, 'persona.organization_id') && 
			props.site.persona.organization_id !== organizationID) {
			setOrganizationID(props.site.persona.organization_id);
		}
		
		if(props.objExists(props.site, 'site_language') &&
			props.site.site_language !== languageCode) {
				setLanguageCode(props.site.site_language);
			}else if(!languageCode){
				setLanguageCode('en');
			}
	}, [props.site]);
	
	useEffect(() => {
		if(props.objExists(props.user, 'user_id')) {
			if(userID !== props.user.user_id) {
				setUserID(props.user.user_id);
			}
			
			if(sessionStorage.getItem('token') && 
				sessionStorage.getItem('token') !== token) {
					setToken(sessionStorage.getItem('token'));
			}else if(localStorage.getItem('token') && 
						localStorage.getItem('token') !== token) {
							setToken(localStorage.getItem('token'));
			}
		}	
	}, [props.user]);
	
	useEffect(() => {
		if(userID && token && 
			organizationID && languageCode) {
			const taskParams = [
				{name: 'process', value: 'get_tasks'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'organization_id', value: organizationID},
				{name: 'language_code', value: languageCode},
				{name: 'task_id', value: pgParams.task_id}
			];
			
			if(!props.objExists(props.user, 'task') || 
				props.objGetValue(props.user, 'reload.task') === true) {
				
				if(props.objGetValue(props.user, 'reload.task') === true) {
					props.userRemoveVal(`reload.task`);
				}
				
				props.userLoadState(taskParams, 'tasks', 'task');
				
				if(intervalTask) {
					clearInterval(intervalTask);
				}
				
				intervalTask = setInterval(()=> {
					if(document.visibilityState === 'visible') {
						props.userLoadState(taskParams, 'tasks', 'task');
					}
				}, props.minToMS(5));
			}
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userID, token, organizationID, languageCode, props.user]);
	
	useEffect(() => {
		if(props.objExists(props.user, 'task') && 
			props.user.task !== 'task not found' &&
			task !== props.user.task) {
			setTask(props.user.task);
		}else if(props.objGetValue(props.user, 'task') === 'task not found') {
			setNoTask(true);
		}
	}, [props.user.task]);
	
	useEffect(() => {
		if(task && userID && token) {
			if(props.objExists(task, 'item_id') && 
				(!props.user.task.item ||
					props.objGetValue(props.user, 'reload.task.item'))) {
				const paramsItem = [
					{name: 'process', value: 'get_order_item'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'item_id', value: task.item_id},
					{name: 'task_id', value: task.task_id}
				];
				props.userLoadState(paramsItem, 'orders', 'task.item');
				if(props.objExists(props.user, 'reload.task.item')) {
					props.userRemoveVal('reload.task.item');
				}else{
					setItemLoading(true);
				}
			}
			
			if(props.objExists(task, 'project_id') && 
				props.objExists(task, 'organization_id') && 
				(!props.user.task.project ||
					props.objGetValue(props.user, 'reload.task.project'))) {
				const paramsItem = [
					{name: 'process', value: 'get_projects'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'project_id', value: task.project_id},
					{name: 'organization_id', value: task.organization_id}
				];
				props.userLoadState(paramsItem, 'orders', 'task.project');
				if(props.objExists(props.dashboard, 'reload.task.project')) {
					props.dashboardDeleteVal('reload.task.project');
				}else{
					setProjectLoading(true);
				}
			}	
		} 
	}, [task, userID, token, props.user.reload]);
	
	useEffect(() => {
		if(props.objExists(task, 'item.item_id') &&
			task.item !== item) {
			setItem(task.item);
			itemLoading && setItemLoading(false);
		}
	}, [task && task.item]);
	
	useEffect(() => {
		if(props.objExists(task, 'project.project_id') &&
			task.project !== project) {
			setProject(task.project);
			projectLoading && setProjectLoading(false);
		}
	}, [task && task.project]);
	
	return (
		<article>
			<p>
			<Link to="/user">
				<IconSVG icon="arrow" className="p rotate270" />
				Return to account
			</Link>
			</p>
			{task && languageCode ?
				<Fragment>
					<h2>
						{props.objExists(task, `${languageCode}.task_name`) ? 
						props.capitalizeString(task[languageCode].task_name, true) : 
						`Task ${task.task_id}`}
					</h2> 
					<TaskInterface {...props}
						userID={userID}
						token={token}
						languageCode={languageCode}
						task={task}
						item={item}
						project={project}
						taskLoading={taskLoading}
						itemLoading={itemLoading}
						projectLoading={projectLoading}
						stateLocation='user'
						/>
				</Fragment>	
			:
				noTask ?
					<h3>Task not available</h3>
				:
					<Loader label="Loading task..."  />
			}
		</article>
	)
}