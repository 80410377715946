import React, {useEffect, useState, Fragment} from 'react';

import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import CoreRule from 'components/atoms/CoreRule';

export default (props) => {
  const [languageCode, setLanguageCode] = useState(null);
  const [subtotalItems, setSubtotalItems] = useState(null);
  const [chargesLabel, setChargesLabel] = useState(null);
  const [charges, setCharges] = useState(null);
  const [chargesArr, setChargesArr] = useState(null);
  const [chargesArrTotal, setChargesArrTotal] = useState(0);
  const [shipping, setShipping] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [tax, setTax] = useState(null);
  const [taxRates, setTaxRates] = useState(null);
  const [total, setTotal] = useState(null);
  const [displayCharges, setDisplayCharges] = useState(true);
  
  const labelUnavailable = "To Be Determined";

  useEffect(() => {
    if(props.languageCode &&
      props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);

  useEffect(() => {
    if (props.financialType === 'project') {
      if (props.objGetValue(props.project, `project_subtotal_items`)) {
        setSubtotalItems(props.project.project_subtotal_items);
      }
      if (props.objGetValue(props.project, `project_charges`)) {
        setChargesLabel('Order Charges');
        setCharges(props.project.project_charges);
      }
      if (props.objGetValue(props.project, `charges`)) {
        let chargesArrTotal = 0;
        props.project.charges.map((charge, index) => {
          chargesArrTotal += parseFloat(charge.charge_value)
        })
        setCharges(parseFloat(props.project.project_charges) - parseFloat(chargesArrTotal));
        setChargesArr(props.project.charges);
        setChargesArrTotal(chargesArrTotal);
      }
      if (props.objGetValue(props.project, `project_shipping`)) {
        setShipping(props.project.project_shipping);
      }
      if (props.objGetValue(props.project, `project_subtotal`)) {
        setSubtotal(props.project.project_subtotal);
      }
      if (props.objGetValue(props.project, `project_tax`)) {
        setTax(props.project.project_tax);
      }
      if (props.objGetValue(props.project, `project_tax_rates`)) {
        setTaxRates(props.project.project_tax_rates);
      }
      if (props.objGetValue(props.project, `project_total`)) {
        setTotal(props.project.project_total);
      }
    } else if (props.financialType === 'order') {
      if (props.objGetValue(props.order, `order_subtotal_items`)) {
        setSubtotalItems(props.order.order_subtotal_items);
      }
      if (props.objGetValue(props.order, `order_charges`)) {
        setChargesLabel('Location Charges');
        setCharges(props.order.order_charges);
      }
      if (props.objGetValue(props.order, `charges`)) {
        let chargesArrTotal = 0;
        props.order.charges.map((charge, index) => {
          chargesArrTotal += parseFloat(charge.charge_value)
        })
        setCharges(parseFloat(props.order.order_charges) - parseFloat(chargesArrTotal));
        setChargesArr(props.order.charges);
        setChargesArrTotal(chargesArrTotal);
      }
      if (props.objGetValue(props.order, `order_shipping`)) {
        setShipping(props.order.order_shipping);
      }
      if (props.objGetValue(props.order, `order_subtotal`)) {
        setSubtotal(props.order.order_subtotal);
      }
      if (props.objGetValue(props.order, `order_tax`)) {
        setTax(props.order.order_tax);
      }
      if (props.objGetValue(props.order.billing, `order_tax_rates`)) {
        setTaxRates(props.order.billing.order_tax_rates);
      }
      if (props.objGetValue(props.order, `order_total`)) {
        setTotal(props.order.order_total);
      }
      if (parseFloat(props.objGetValue(props.order, `order_total`)) === parseFloat(props.objGetValue(props.project, `project_total`))) {
        setDisplayCharges(false);
      }
    } else if (props.financialType === 'location') {
      if (props.objGetValue(props.location.pricing, `location_subtotal_items`)) {
        setSubtotalItems(props.location.pricing.location_subtotal_items);
      }
      if (props.objGetValue(props.location.pricing, `location_charges`)) {
        setCharges(props.location.pricing.location_charges);
      }
      if (props.objGetValue(props.location.pricing, `charges`)) {
        let chargesArrTotal = 0;
        props.location.pricing.charges.map((charge, index) => {
          chargesArrTotal += parseFloat(charge.charge_value)
        })
        setChargesArr(props.location.pricing.charges);
        setChargesArrTotal(chargesArrTotal);
      }
      if (props.objGetValue(props.location.pricing, `location_shipping`)) {
        setShipping(props.location.pricing.location_shipping);
      }
      if (props.objGetValue(props.location.pricing, `location_subtotal`)) {
        setSubtotal(props.location.pricing.location_subtotal);
      }
      if (props.objGetValue(props.location.pricing, `location_tax`)) {
        setTax(props.location.pricing.location_tax);
      }
      if (props.objGetValue(props.location.pricing, `location_tax_rates`)) {
        setTaxRates(props.location.pricing.location_tax_rates);
      }
      if (props.objGetValue(props.location.pricing, `location_total`)) {
        setTotal(props.location.pricing.location_total);
      }
      if (parseFloat(props.objGetValue(props.location.pricing, `location_total`)) === parseFloat(props.objGetValue(props.project, `project_total`))) {
        setDisplayCharges(false);
      }
    }
  }, [props.project, props.order, props.location]);
  
  function updateValue(name, value) {
    const updateValueParams = [
      {name: 'process', value: 'update_value'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'field_id', value: props.financialType === 'order' ? props.order.order_id : props.financialType === 'location' ? props.location.location_id :  props.project.project_id},
      {name: 'field_name', value: name},
      {name: 'field_value', value: value},
    ]
    props.adminLoadState(updateValueParams, 'commonTasks');
    const updateFinancialsParams = [
      {name: 'process', value: 'update_project_financials'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'project_id', value: props.project.project_id},
    ]
    const callbackParams = [
      {name: 'process', value: 'get_projects'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'organization_id', value: props.organizationID},
      {name: 'project_id', value: props.project.project_id},
    ]
    props.adminLoadState(updateFinancialsParams, 'orders', 'project', callbackParams, 'orders');
  }

  return (
    displayCharges && total && parseFloat(total) > 0 &&
      <Fragment>
        <div className="truncate">
          <div className="cart-box cart-totals" style={{marginBottom: '0em'}}>
            {subtotalItems !== subtotal &&
              <Fragment>
                <div className="cart-totals-set">
                  <div className="grid col-2 cells">
                    <div className="column"><label>Items Subtotal</label></div>
                    <div className="column">
                      <div className="flex align-right cart-price-value">{subtotalItems ? props.priceDisplay(subtotalItems, languageCode) : labelUnavailable}</div>
                    </div>
                  </div>
                  {
                    parseFloat(charges) !== parseFloat(chargesArrTotal) && (
                      <div className="grid col-2 cells">
                        <div className="column"><label>{`${chargesLabel ? chargesLabel : 'Charges'}`}</label></div>
                        <div className="column">
                          <div className="flex align-right cart-price-value">{charges ? props.priceDisplay(charges, languageCode) : labelUnavailable}</div>
                        </div>
                      </div>
                    )
                  }
                  {
                    chargesArr && Array.isArray(chargesArr) && chargesArr.length > 0 &&
                      chargesArr.map((charge, index) => {
                        return (
                          <div key={`prjtfinchrg${index}`} className="grid col-2 cells">
                            <div className="column"><label>{`${charge.charge_name}`}</label></div>
                            <div className="column">
                              <div className="flex align-right cart-price-value">{charge.charge_value ? props.priceDisplay(charge.charge_value, languageCode) : labelUnavailable}</div>
                            </div>
                          </div>
                        )
                      })
                  }
                  <div className="grid col-2 cells">
                    <div className="column"><label>Shipping</label></div>
                    <div className="column">
                      <div className="flex align-right cart-price-value">
                        {props.isAdmin && props.financialType === 'location' ?
                          <LiveFieldInput name={`${props.financialType}_shipping`}
                            type='price'
                            id={null}
                            disabled={props.isAdmin && props.financialType === 'location' ? false : true}
                            value={shipping}
                            action={updateValue}
                            className={shipping ? '' : 'no-print'} />
                          :
                          shipping ? props.priceDisplay(shipping, languageCode) : labelUnavailable
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <CoreRule vertical={true} />
              </Fragment>
            }
            <div className="cart-totals-set">
              <div className="grid col-2 cells">
                <div className="column"><label>Subtotal</label></div>
                <div className="column">
                  <div className="flex align-right cart-price-value">{subtotal ? props.priceDisplay(subtotal, languageCode) : labelUnavailable}</div>
                </div>
              </div>
              {taxRates &&
                Object.keys(taxRates).map((item, index) => {
                  const taxRate = Intl.NumberFormat("en-GB", {style: "percent",minimumFractionDigits: 0, maximumFractionDigits: 2}).format(taxRates[item].tax_rate);
                  return (
                    <div key={`prjtfintxrt${index}`} className="grid col-2 cells">
                      <div className="column"><label>{`${item} (${taxRate})`}</label></div>
                      <div className="column">
                        <div className="flex align-right cart-price-value">{taxRates[item].tax_rate_total ? props.priceDisplay(taxRates[item].tax_rate_total, languageCode) : labelUnavailable}</div>
                      </div>
                    </div>
                  )
                })
              }
              <div className="grid col-2 cells">
                <div className="column"><label>Total</label></div>
                <div className="column">
                  <div className="flex align-right cart-price-value">{total ? props.priceDisplay(total, languageCode) : labelUnavailable}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  )
}