import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';

import IconSVG from 'components/icons/IconSVG';

import * as utils from 'methods/site';

export default (props) => {
  const selectRef = useRef();
  const inputRef = useRef();
  const ctlRef = useRef();
  const [selected, setSelected] = useState(null);
  const [isActive, setActive] = useState(null);
  const [isClick, setClick] = useState(false);
  const [selectOptions, setSelectOptions] = useState(null);
  const [navOption, setNavOption] =  useState(null);
  const [selectName, setSelectName] = useState(false);
  const [selectIndex, setSelectIndex] = useState(null);

  // FOR REVISED SELECT
  const refContainer = useRef();
  const [options, setOptions] = useState(null);
  const [optionValue, setOptionValue] = useState(null);
  const [activeSet, setActiveSet] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const [currentKey, setCurrentKey] = useState('');
  const [optionClick, setOptionClick] = useState(null);
  const [deactivate, setDeactivate] = useState(false);

  const [selectActive, setSelectActive] = useState(false);
  const [changeInProgress, setChangeInProgress] = useState(false);

  // HOOKS
  useEffect(() => {
    
    return () => {
      deactivateSelect();
    }
  }, []);
  
  useEffect(() => {
    if(props.option_groups &&
        Array.isArray(props.option_groups) &&
        props.option_groups.length > 0) {
          
        let optGroups = [];
        if(!props.hideSelect && props.option_groups.length > 1) {
          optGroups.push(
            {options: [{option_name: props.placeholder ? `${props.placeholder}...` : 'Select...', option_value: ''}]}
          );
        }
        props.option_groups.map((optGroup, index, array) => {
          let optionGroup = {options: []};
          if(optGroup.group_name) {
            optionGroup['group_name'] = optGroup.group_name;
          }
          if(!props.hideSelect && index === 0 && array.length === 1) {
            optionGroup.options.push({option_name: props.placeholder ? `${props.placeholder}...` : 'Select...', option_value: ''});
          }
          optionGroup.options.push(...optGroup.options);
          optGroups.push(optionGroup);
        })
      setOptions(optGroups);  
    }
  }, [props.option_groups]);

  useEffect(() => {
    let optionList = [];
    
    options && Array.isArray(options) &&
    options.map((optgroup, index) => {
      if(options.length > 1) {
          optionList.push(<div className="form-select-input-optgroup"
                              key={`optgrp${index}`}
                              rel={null}
                              onClick={(e) => {e.preventDefault()}}>
                                  {optgroup.group_name}
                                </div>);
      }


      optgroup.options && Array.isArray(optgroup.options) &&
      optgroup.options.map((option, oIndex) => {
        optionList.push(<div className={`form-select-input-option${
                                          String(option.option_value).toLowerCase() === String(optionValue).toLowerCase() ? ' nav-focus' : '' }`}
                            key={`slctOp${oIndex}`}
                            rel={option.option_value}
                            onClick={(e) => {setOptionClick(option.option_value)}} >
                              {option.option_name}
                            </div> )
        if(props.defaultValue && String(option.option_value).toLowerCase() === String(optionValue).toLowerCase()) {
          setSelected({name: option.option_name, value: option.option_value});
        }
      })
    })
    if(optionList.length > 0) {
      setSelectOptions(optionList);
    }
  }, [options, optionValue]);
  
  useEffect(() => {
    if(options) {
      if(!props.defaultValue && 
          !props.value) {
          if(selected === null ||
              String(selected).toLowerCase() !== String(options[0].options[0].option_value).toLowerCase()) {
            setSelected({name: options[0].options[0].option_name, value: options[0].options[0].option_value});
          }else if(selected &&
                  String(selected).toLowerCase() !== String(options[0].options[0].option_value).toLowerCase()) {
            setSelected({name: options[0].options[0].option_name, value: options[0].options[0].option_value});
          }
      }
    }
  }, [options, props.defaultValue, props.value])
  
  useEffect(() => {
    if(!optionValue && props.defaultValue) {
      setOptionValue(props.defaultValue);
    }else if(selected) {
      setOptionValue(selected.value);
    }
  }, [props.defaultValue, selected])


  // PASSED THROUGH ON LOAD FUNCION FOR CONDITIONAL FILES;
  useEffect(() => {
    if(props.conditionalOnLoad && selectRef.current !== null) {
      props.conditionalOnLoad(selectRef.current, props.name, props.defaultValue);
    }
  }, [props.conditionalOnLoad, selectRef]);

  // ADJUSTING THE SELECT VALUE IF THE NAME OR INDEX CHANGES - IN THE CASE OF INCREASING A NAME INDEX eg name[1] > name[2]
  useEffect(() => {
    // ************************************ //
    // NOTE: ADDED changeInProgress TEST HERE TO ELIMINATE ISSUE WHERE onChange WAS CALLED DURING LOAD - MAY CAUSE ISSUES IN OTHER PLACES
    // ************************************ //
    if(changeInProgress && (props.name !== selectName || props.index !== selectIndex)) {
      let target = inputRef.current;
      while(target && target.tagName.toLowerCase() !== 'fieldset' && target.tagName.toLowerCase() !== 'body') {
        target = target.parentNode;
      }
      target &&
      selectName &&
      props.clearConditional &&
      props.clearConditional(target, selectName);

      if(!props.defaultValue && !props.value) {
        // setSelected({name: props.option_groups[0].options[0].option_name, value: props.option_groups[0].options[0].option_value});
      }else{
        props.option_groups.map(option_group => {
          option_group.options.map(option => {
            if(String(option.option_value).toLowerCase() === String(props.defaultValue).toLowerCase() &&
              option.option_value !== selected) {
              setSelected({name: option.option_name, value: option.option_value});
            }
          })
        })
      }
      setSelectName(props.name);
      props.index &&
      setSelectIndex(props.index);
      setChangeInProgress(false);
    }
  }, [props.name, props.defaultValue, props.index]);

  useEffect(() => {
    if(selected) {
      handleChange(inputRef.current);
    }
  }, [selected]);

  useEffect(() => {
    if(optionClick || optionClick === '' || 
        optionClick === 0 || (optionClick === null && selected)) {
      selectOption(optionClick);
      setOptionClick(false)
    }
  }, [optionClick]);

  useEffect(() => {
    if(deactivate) {
      deactivateSelect();
      setDeactivate(false);
    }
  }, [deactivate]);

  useEffect(() => {
    if(isActive) {
      var _sizeListener = function() {
        setOptionSize(isActive);
      }

      var _input = (e) => {
        handleInput(e);
      }

      var _click = (e) => {
        clickListener(e);
      }
      
      // var _scroll = (e) => {
      //   scrollListener(e);
      // }

      window.addEventListener('resize', _sizeListener, true);
      document.addEventListener('keydown', _input, true);
      document.body.addEventListener('click', _click, true);

      return () => {
        window.removeEventListener('resize', _sizeListener, true);
        document.removeEventListener('keydown', _input, true);
        document.body.removeEventListener('click', _click, true);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if(isActive && (currentKey.length === 1 || currentKey === 'Backspace')) {
      const optionNodes = Array.from(isActive.querySelectorAll('.form-select-input-option'));
      const prevFocus = optionNodes.filter(option => {return option.classList.contains('nav-focus')});
      let searchString = '';
      if(currentKey === 'Backspace') {
        searchString = textSearch.substr(0, textSearch.length-1);
      }else{
        searchString = textSearch+currentKey.toLowerCase();
      }

      const focusNode = optionNodes.find(option => {return option.innerHTML.toLowerCase().substr(0, searchString.length) === searchString.toLowerCase()});

      if(focusNode) {
        prevFocus && prevFocus.length > 0 &&
        prevFocus.map(prev => {
          prev !== focusNode &&
          prev.classList.remove('nav-focus');
        })

        !focusNode.classList.contains('nav-focus') &&
        focusNode.classList.add('nav-focus');
      }

      if(focusNode) {
        setTextSearch(searchString);
      }else{
        setTextSearch(currentKey);
      }
      setCurrentKey('');
    }
  }, [currentKey]);

  useEffect(() => {
    if(selectActive && Array.isArray(selectOptions) && selectOptions.length > 0) {
        let parent = ctlRef.current;

        while(!parent.classList.contains('form-select') && parent.tagName.toLowerCase() !== 'body') {
          parent = parent.parentNode;
        }
        let target = parent;
        for(var child of target.childNodes) {
          if(child.classList.contains('file-select-input')) {
            target = child;
          }
        }
        const existingMenu = document.querySelector('.form-select-input-options');
        if(existingMenu) {
          existingMenu.remove();
        }

        const optionList = document.createElement('div');
        optionList.classList.add('form-select-input-options');
        if(props.className){
          optionList.classList.add(props.className);
        }
        setActive(optionList);
        
        ReactDOM.render(selectOptions, optionList);
        if(document.body.classList.contains('modal-active')) {
          const activeModal = document.querySelectorAll('.modal')[0];
          if(!optionList.classList.contains('active')) {
            optionList.classList.add('active');
          }
          setOptionSize(optionList, activeModal);
          activeModal.append(optionList);
        }else{
          if(!optionList.classList.contains('active')) {
            optionList.classList.add('active');
          }
          setOptionSize(optionList);
          document.body.append(optionList);
        }
    }
  }, [selectActive, selectOptions, props.option_groups]);

  // FUNCTIONS
  function handleInput(e) {
    switch(e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        e.preventDefault();
        traverseOptions(e.key.replace('Arrow', '').toLowerCase());
        break;
      case 'Enter':
        e.preventDefault();
        acceptOption(e);
        break;
      case 'Escape':
      case 'Tab':
        e.preventDefault();
        deactivateSelect();
      default:
        e.preventDefault();
        setCurrentKey(e.key);
        break;
    }
  }

  function traverseOptions(direction) {
    if(isActive) {
      let optionNodes = Array.from(isActive.querySelectorAll('.form-select-input-option'));
      if(direction === 'up') {
        optionNodes.reverse();
      }
      const focusNode = optionNodes.findIndex(node => node.classList.contains('nav-focus'));
      if(focusNode >= 0 && focusNode+1 !== optionNodes.length) {
        optionNodes[focusNode].classList.remove('nav-focus');
        optionNodes[focusNode+1].classList.add('nav-focus');
      }else if(focusNode < 0){
        optionNodes[0].classList.add('nav-focus');
      }
    }
  }

  function acceptOption(e) {
    if(isActive) {
      let optionNodes = Array.from(isActive.querySelectorAll('.form-select-input-option'));
      const focusNode = optionNodes.find(node => node.classList.contains('nav-focus'));
      const selectedNode = optionNodes.find(node => node.classList.contains('selected'));
      if(selectedNode && selectedNode !== focusNode) {
        selectedNode.classList.remove('selected');
      }
      if(focusNode) {
        selectOption(focusNode.getAttribute('rel'), e);
      }
    }
  }

  // ON VALUE CHANGE - INCLUDING PASSED THROUGH ON CHANGE FUNCTIONS
  function handleChange(e) {
    const dVal = props.defaultValue ?
                    props.defaultValue :
                      props.value ? 
                        props.value : '';
    if(selected.value !== dVal) {
      props.onChange &&
      props.onChange(props.returnObject ? {name: e.name, value: e.value} : e);
      if(props.updateAction) {
        if(Array.isArray(props.updateAction)) {
          props.updateAction.map(action => {
            action(selectRef.current);
          })
        }
      }
    }
  }

  function clickListener(e) {
    if(isActive) {
      let target = e.target;
      while(!target.classList.contains('form-select-input-options') &&
            !target.classList.contains('form-select') &&
            target.tagName.toLowerCase() !== 'body') {
        target = target.parentNode;
      }
      
      if(target.tagName.toLowerCase() === 'body' || 
          (target !== selectRef.current && target !== isActive)) {
        deactivateSelect();
      }
    }
  }


  // REMOVES CURRENT OPTION SET FROM DOM
  function deactivateSelect() {
    if(isActive) {
      isActive.remove();
    }
    setActive(null);
    setSelectActive(false);
  }

  // SETS OPTION SIZE BASED ON SIZE AND POSITION OF SELECT INPUT DIV
  function setOptionSize(optionList, modal = false) {
    const fontAdjust = parseInt(window.getComputedStyle(selectRef.current).fontSize)*1.5;
    const targetSizes = selectRef.current.getBoundingClientRect();
    optionList.style.width = `${targetSizes.width}px`;
    
    if(modal) {
      const modalSizes = modal.getBoundingClientRect();
      
      const maxHeight = modalSizes.height - targetSizes.top;
      
      optionList.style.top = `${targetSizes.top-modalSizes.top+modal.scrollTop+fontAdjust}px`;
      optionList.style.left = `${targetSizes.left-modalSizes.left}px`;
      // optionList.style.maxHeight = `${maxHeight}px`;
    }else{
      optionList.style.top = `${window.scrollY+targetSizes.top+fontAdjust}px`;
      optionList.style.left = `${window.scrollX+targetSizes.left}px`;
    }
  }

  // SELECTS THE OPTION FROM THE OPTION LIST AND CLOSES (REMOVES) THE OPTION SET
  function selectOption(value) {
    setChangeInProgress(true);
    if((value || value === 0 || value === '' || value === null) && options && Array.isArray(options)) {
        if(value && value !== '') {
          let optionMatch = false;
          options.map(optGroup => {
              const optFind = optGroup.options.find(opt => opt.option_value === value)
              if(optFind) {
                optionMatch = true;
                if({name: optFind.option_name, value: optFind.option_value} !== value) {
                  setSelected({name: optFind.option_name, value: optFind.option_value});  
                }
              }
          });
        }else if (value !== selected.value){
          setSelected({name: props.placeholder ? 
                              `${props.capitalizeString(props.placeholder, true)}...`  :
                                  'Select...', 
                      value: ''});
        }
    }
    deactivateSelect();
  }

  return (
    <div className={`container-overflow${props.className ? ` ${props.className}` : ''}`} ref={refContainer}>
      <div className={`form-select${props.isMissing ? ` form-input-missing` : ''}`}
            ref={selectRef}
            tabIndex={props.tabIndex}
            onMouseDown={(e)=>{e.preventDefault()}}
            onFocus={(e)=>{!selectActive && setSelectActive(true)}}
            >

        <label htmlFor={props.id? props.id : null}
              className="input-label" >
          {props.label ? props.label : props.name}
          {props.required && <span className="flag-required">&#10035;</span>}
        </label>
        <div className="form-select-input" ref={ctlRef}
              onClick={(e)=>{!selectActive && setSelectActive(true)}}>
          <div className="form-select-input-value">
            <div className="form-select-input-value-selected">
              {selected ? selected.name : props.placedholder ? 
                props.capitalizeString(props.placeholder, true) : 
                'Select...'}
            </div>
          </div>
          <IconSVG icon="arrow" />
        </div>
        <input type="hidden" name={props.name} value={selected && selected.value && selected.value !== 'na' ? selected.value : ''} required={props.required ? true : false} ref={inputRef} />
      </div>
    </div>
  )
}
