import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import MediaHero from 'components/profiles/media/MediaHero';
import LocationBlock from 'components/profiles/locations/LocationBlock';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import InfoPopUp from 'components/profiles/common/InfoPopUp';
import ProjectFinancials from 'components/profiles/projects/ProjectFinancials';

import * as utils from 'methods/site';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [projectStatus, setProjectStatus] = useState(null);
	const [coreUser, setCoreUser] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [isAdmin, setIsAdmin] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [location, setLocation] = useState(null);
	const [items, setItems] = useState(null);
	
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		props.organizationID &&
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	  }, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.location && 
			props.location !== location) {
			setLocation(props.location);
		}
	}, [props.location]);
	
	useEffect(() => {
		if(props.coreOrg && 
	  	props.coreOrg !== coreOrg) {
	  	setCoreOrg(props.coreOrg);
		}
  	}, [props.coreOrg]);
  
  	useEffect(() => {
		if(props.coreUser && 
	  	props.coreUser !== coreUser) {
	  	setCoreUser(props.coreUser);
		}
  	}, [props.coreUser]);
  
  	useEffect(() => {
		if(props.projectStatus && 
	  	props.projectStatus !== projectStatus) {
	  	setProjectStatus(props.projectStatus);
		}
  	}, [props.projectStatus]);
  
  	useEffect(() => {
		if(props.isAdmin && !isAdmin) {
	  	setIsAdmin(true);
		}else if(!props.isAdmin && isAdmin){
	  	setIsAdmin(false);
		}
  	}, [props.isAdmin]);
  
  	useEffect(() => {
		props.userAccess &&
		props.userAccess !== userAccess &&
		setUserAccess(props.userAccess);
  	}, [props.userAccess]);
  	
  	useEffect(() => {
		if(isAdmin && userAccess){
	  		if(userAccess.super) {
				setCanEdit(true);
	  		}else if(props.objGetValue(userAccess, `staffAdmin.${coreOrg}`) === true &&
			  		['new', 'fulfilling order', 'approved'].includes(projectStatus)) {
				setCanEdit(true);
	  		}
		}else if(coreUser === userID && 
	  	['new'].includes(projectStatus)) {
	  	setCanEdit(true);
		}
  	}, [isAdmin, userAccess, coreOrg, coreUser, userID, projectStatus]);
	
	useEffect(() => {
		if(Array.isArray(props.items) && 
			props.objExists(location, 'items')) {
			if(Object.entries(location.items).length > 0) {
				let locItems = [];
				Object.entries(location.items).map(lItemQty => {
					const lItem = props.items.find(itm => parseInt(itm.item_id) === parseInt(lItemQty[0]));
					if(lItem) {
						locItems.push({
							...lItem,
							item_subtotal: parseFloat(lItem.product_price) * (parseInt(lItemQty[1]) / parseInt(lItem.product_quantity)),
							product_quantity: parseInt(lItemQty[1]),
						})
					}
				});
				if(locItems.length > 0) {
					setItems(locItems);
				}
			}
		}
	}, [props.items, location]);
	
	// FUNCTIONS
	function loadAddEdit(type) {
		
	}
	
	function updateItemQuantity(name, value, params) {
		const paramsItemQty = [
		  {name: 'process', value: 'update_order_item_quantity'},
		  ...params,
		  {name: 'token', value: token},
		  {name: 'item_quantity', value: value}
		];
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](paramsItemQty, 'orders', 'reload.project');
	}
	
	//console.log('TEST items', items);
	
	return (
		<article className={`order-location${props.multiple ? ' repeating' : ''}`}>
			{location ? 
				<Fragment>
				{props.multiple &&
					<h3>{`${props.index === 0 ? 'Primary ' : ''}Location${props.index > 0 ? ` ${props.index+1}` : ''}`}</h3>
				}
				{(location.shipping_created || 
					location.carrier_name || 
					location.shipping_tracking || 
					location.shipping_cost) && 
					<div className="block">
						<div className="block-heading">Shipping</div>
						{location.shipping_created && 
							<div className="block-row">
								<b>{`SHIPPED ${props.dateDisplay(location.shipping_created)}`}</b>
							</div>
						}
						{location.carrier_name &&
							<div className="block-row">
								Via:&nbsp;<b>{` ${location.carrier_name}`}</b>
							</div>
						}
						{location.shipping_tracking &&
							<div className="block-row">
								Tracking:&nbsp;<b>{location.carrier_tracking_url ?
									<a target="_blank"
										href={`${location.carrier_tracking_url}${location.shipping_tracking}`}>
										{location.shipping_tracking}
									</a>
									:
										location.shipping_tracking
									}</b>
							</div>
						}
						{isAdmin && location.shipping_cost && !isNaN(parseFloat(location.shipping_cost)) &&
							parseFloat(location.shipping_cost) > 0 &&
 							<div className="block-row">
								Charge:&nbsp;<b>{`${props.priceDisplay(parseFloat(location.shipping_cost))}`}</b>
							</div>
						}
					</div>
				}
				<LocationBlock {...props}
						className={props.multiple || props.shrink ? 'horizontal'  : null}
						location={location}
						locationHeading="Ship to:"
						actionLocationAddEdit={loadAddEdit}
						contact={location}
						contactHeading="Attn:"
						actionContactAddEdit={loadAddEdit}
						requiredFields={[
							'location_address_1',
							'location_city',
							'location_province',
							'location_postal_code',
							 'location_country',
							'contact_name_first',
							'contact_name_last',
							'contact_email',
							'contact_phone'
						]}
						allowEdit={false} />
				<div className="field">
					<label>
						{`${(location.location_shipping_method && 
							location.location_shipping_method.toLowerCase() === 'digital')  ||
								(props.order_shipping_method && 
								props.order_shipping_method.toLowerCase() === 'digital') ? 'Delivery' :
									'Shipping'} Method`}
					</label>
					<div className="field-value">
						<b>{location.location_shipping_method ?  
								location.location_shipping_method :
							      props.orderShippingMethod ?
							  			props.orderShippingMethod : 'Unknown'}</b>
					</div>
					{props.objExists(location, `shipping_method.shipping_info`) &&
						<InfoPopUp {...props} 
							icon="info"
							tooltip="Shipping info..."
							info={props.location.shipping_method.shipping_info}
						/>
					}
				</div>
				{languageCode && props.multiple &&
				Array.isArray(items) &&
					<Fragment>
						<label style={{color: 'rgba(51,51,51,0.3)', fontSize: '1em', marginBottom: '1em', marginTop: '0.5em'}}>Items shipping to this location</label>
						{items.map((item, iIndex) => {
							return(
								<Fragment key={`prjtolitm${iIndex}`}>
									<div className="list-row border-bottom">
										<div className="order-item-col-left">
											<div style={{width: '5em'}}>
												{props.objExists(item, `details.${item.language_code}.media`) &&
													Array.isArray(item.details[item.language_code].media) &&
													item.details[item.language_code].media.length > 0 &&
														<MediaHero {...props} 
															mediaFileName={item.details[item.language_code].media[0].media_file_name}
															mediaCaption={item.details[item.language_code].media[0].media_caption}
															className="list-item-media"
															isAdmin={false} />
												}
											</div>
											<div>
												<b>
													{item.product_name ? item.product_name :
														props.objExists(item, `details.${item.language_code}.product_name`) ? 
														item.details[item.language_code].product_name : 'Product'
													}
												</b>
												<div style={{fontSize: '0.85em'}}>
													{(item.details[item.language_code].product_code && item.details[item.language_code].product_sku && item.details[item.language_code].product_code.toLowerCase() !== item.details[item.language_code].product_sku.toLowerCase() || (item.details[item.language_code].product_code && !item.details[item.language_code].product_sku)) && `Code: ${item.details[item.language_code].product_code.toUpperCase()}`}
													{item.details[item.language_code].product_code && item.details[item.language_code].product_sku && item.details[item.language_code].product_code.toLowerCase() !== item.details[item.language_code].product_sku.toLowerCase() && ` | `}
													{props.objExists(item, `details.${item.language_code}.product_sku`) && `SKU: ${item.details[item.language_code].product_sku.toUpperCase()}`}
												</div>
											</div>
										</div>
										<div className="order-item-col-right">
											<div className="order-item-quantity-col text-center">
												<div className="cart-label">Quantity</div>
												  <div className="order-item-quantity">
													<LiveFieldInput {...props}
														type="number"
														name="item_quantity"
														value={item.product_quantity}
														optionalParams={[
														{name: 'item_id', value: item.item_id},
														{name: 'location_id', value: location.location_id},
														{name: 'order_id', value: props.orderID},
														{name: 'user_id', value: userID}
														]}
														action={updateItemQuantity}
														disabled={canEdit && location.location_id ? false : true} />
												</div>
											</div>
											{!props.hidePricing && 
												<div className="order-item-price-col text-center">
													<div className="cart-label">Item Total</div>
													<div className="order-item-price">
													  <LiveFieldInput {...props}
														type="decimal"
														name="item_price"
														value={utils.priceDisplay(item.item_subtotal, languageCode)}
														disabled={true} /> 
													</div>
												  </div>
											}
										</div>
									</div>
								</Fragment>
							)
						})}
						{/* <div className="grid col-2 cells">
							<div className="column-header">Qty</div>
							<div className="column-header">
								<div className="flex-row" style={{marginLeft: '0em'}}>
									<div style={{flex: '1 0 auto'}}>Product</div>
									{!props.hidePricing &&
										<div style={{flex: '0 0 auto', textAlign: 'right', marginLeft: '0em'}}>Item Subtotal</div>
									}
								</div>
							</div>
							{items.map((item, iIndex) => {
								return(
									<Fragment key={`prjtolitm${iIndex}`}>
										<div className="column">
											<LiveFieldInput {...props}
												type="number"
												name="item_quantity"
												value={item.product_quantity}
												optionalParams={[
								    			{name: 'item_id', value: item.item_id},
								    			{name: 'location_id', value: location.location_id},
								    			{name: 'order_id', value: props.orderID},
								    			{name: 'user_id', value: userID}
												]}
												action={updateItemQuantity}
												disabled={canEdit && location.location_id ? false : true} />
										</div>
										<div className="column">
											<div className="flex-row" style={{marginLeft: '0em'}}>
												<div className="truncate" style={{flex: '1 0 3em'}}>
													{item.product_name ? item.product_name :
														props.objExists(item, `details.${languageCode}.product_name`) ? 
														item.details[languageCode].product_name : 'Product'
													}
												</div>
												{!props.hidePricing && 
													<div style={{flex: '0 0 auto', textAlign: 'right', marginLeft: '0em'}}>
														{utils.priceDisplay(item.item_subtotal)}
													</div>
												}
											</div>
										</div>
									</Fragment>
								)
							})}
						</div> */}
					</Fragment>
				 }
				 {props.objGetValue(props, 'hidePricing') !== true &&
				   <ProjectFinancials {...props}
									   userID={userID}
									   token={token}
									   organizationID={organizationID}
									   languageCode={languageCode}
									   isAdmin={isAdmin}
									   project_id={props.project_id}
									   projectStatus={projectStatus}
									   coreUser={coreUser}
									   coreOrg={coreOrg}
									   location={location}
									   userAccess={userAccess}
									   financialType="location" />
				   }
				</Fragment>
			:
				<Loader label="Loading location..." />
			}
		</article>
	)
}