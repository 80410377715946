import React, {useEffect, useState, Fragment} from 'react';
import { useParams } from "react-router";

import Project from 'components/profiles/projects/Project';
import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';
import IconSVG from 'components/icons/IconSVG';

import * as utils from 'methods/site';

export default (props) => {
	props = {...props.children, ...utils};
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [projectID, setProjectID] = useState(null);
	const [project, setProject] = useState(null);
	const [noProject, setNoProject] = useState(null);
	
	const pgParams = useParams();
	
	useEffect(() => {
		return () => {
			props.userRemoveVal('project');
		}
	}, []);
	
	useEffect(() => {
		if(pgParams.project_id &&
			parseInt(pgParams.project_id) !== projectID) {
			setProjectID(parseInt(pgParams.project_id));
		}
	}, [pgParams]);
	
	useEffect(() => {
		if(props.objExists(props.user, 'user_id') &&
			props.user.user_id !== userID) {
			setUserID(props.user.user_id);
		}
		
		if(localStorage.getItem('token') && 
			localStorage.getItem('token') !== token) {
			setToken(localStorage.getItem('token'));
		}else if(sessionStorage.getItem('token') && 
			sessionStorage.getItem('token') !== token) {
			setToken(sessionStorage.getItem('token'));
		}
		
	}, [props.user]);
	
	useEffect(() => {
		if(props.objExists(props.site, 'site_language') &&
			props.site.site_language !== languageCode) {
			setLanguageCode(props.site.site_language);
		}
		
		if(props.objExists(props.site, 'persona.organization_id') &&
			props.site.persona.organization_id !== organizationID) {
			setOrganizationID(props.site.persona.organization_id);	
		}
		
	}, [props.site]);
	
	useEffect(() => {
		if(userID && token &&
			languageCode && organizationID) {
			const projectParams = [
				{name: 'process', value: 'get_projects'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'core_user', value: userID},
				{name: 'project_id', value: projectID}
			];
			
			if(!props.objExists(props.user, 'project') ||
				parseInt(props.objGetValue(props.user, `project.project_id`)) !== parseInt(projectID) ||
				props.objGetValue(props.user, 'reload.project') === true ||
				props.objGetValue(props.user, 'reload.order') === true) {
				props.userLoadState(projectParams, 'orders', 'project');
			}	
		}
	}, [userID, token, languageCode, organizationID, props.user.project, props.user.reload]);
	
	useEffect(() => {
		if(props.objExists(props.user, 'project.project_id') &&
			props.user.project !== project) {
				setProject({...props.user.project});
		}else if(props.objGetValue(props.user, 'project') === 'no results') {
			setNoProject(true);
		}	
	}, [props.user.project]);
	
	useEffect(() => {
		if(project && userID && organizationID) {
			setUserAccess(false);
		}
	}, [project, userID, organizationID]);
		
	return (
		userID && token && languageCode && 
		organizationID && project ?
			<Fragment>
				<article>
					<Link to="/user">
						<IconSVG icon="arrow" className="rotate270" />
						Return to your account
					</Link>
				</article>
				<Project {...props}
						userID={userID}
						token={token}
						languageCode={languageCode}
						organizationID={organizationID}
						location={'user'}
						project={project}
						userAccess={userAccess}
						 />
			</Fragment>
			
		:	
			noProject ?
				<article>
					<Link to="/user">
						<IconSVG icon="arrow" className="rotate270" />
						Return to your account
					</Link>
					<h3>
						<IconSVG icon="alert" className="h3" />
						<span>No order found</span>
					</h3>
				</article>
			:
				<Loader isOverlay={true} label="Loading order..." />
	)
}