import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
// import List from 'components/molecules/List';
// import CheckList from 'components/forms/elements/CheckList';
// import FormContactAddEdit from 'components/forms/common_forms/FormContactAddEdit';
// import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';
import FormBlobAddEdit from 'components/forms/common_forms/FormBlobAddEdit.js';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import Checkbox from 'components/forms/elements/Checkbox';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [allOptions, setAllOptions] = useState(null);
  const [optionList, setOptionList] = useState(null);
  const [activeOptions, setActiveOptions] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(userID && token && 
      (userAccess.super || userAccess.admin)) {
      const shippingParams = [
        {name: 'process', value: 'get_shipping_options'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ];
      if(!props.objExists(props.admin, 'shippingOptions') ||
          props.objGetValue(props.admin, 'reload.shippingOptions') === true) {
            props.adminLoadState(shippingParams, 'organization', 'shippingOptions');
            if(props.objGetValue(props.admin, 'reload.shippingOptions') === true) {
              props.adminDeleteVal('reload.shippingOptions');
            }
        }
      
    }
  }, [userID, token, userAccess, props.admin]);

  useEffect(() => {
    if(props.shippingOptions) {
      let shipOptions = [];
      if(Array.isArray(props.shippingOptions)) {
        props.shippingOptions.map(shop => {
          shipOptions.push(shop);
        })
      };

      if(shipOptions.length > 0) {
        setActiveOptions(shipOptions);
      }
    }

  }, [props.shippingOptions]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'shippingOptions') &&
        Array.isArray(props.admin.shippingOptions)) {
      setOptionList(
        props.admin.shippingOptions.map((option, index) => {
          const actOpt = Array.isArray(activeOptions) ? 
                          activeOptions.find(shopt => shopt.option_id === option.option_id) :
                          null;
          return {option_name: option.option_name,
                  option_value: option.option_id,
                  option_active: actOpt ? true : false,
                  option_info: actOpt ? props.objGetValue(actOpt, `shipping_info`) : false}
        })
      )
    }
  }, [props.admin.shippingOptions, activeOptions]);

  // FUNCTIONS
  function handleClick(option_id, value) {
    if(props.loadOptionChange) {
      Array.isArray(optionList) &&
      optionList.find(pop => pop.option_value === option_id) &&
      props.loadOptionChange(optionList.find(pop => pop.option_value === option_id), value);
    }
  }
  
  function loadAddEditShppingInfo(option_id, shipping_info) {
    props.setModal(
      <FormBlobAddEdit 
        {...props}
        userID={userID}
        token={token}
        organization_id={organizationID}
        option_id={option_id ? option_id : null}
        shipping_info={shipping_info ? shipping_info : null}
        actionCancel={()=>{props.setModal(null)}}
        actionAddEdit={addEditShippingInfo}
      />
    )
  }
  
  function addEditShippingInfo(params) {
    props.setModal(null);
    props.adminLoadState(params, 'organizations', 'reload.persona');
  }
  
  function updateShippingInfo(name, value, optionalParams) {
    const updateParams = [
       {name: 'process', value: 'update_shipping_info'},
       {name: 'user_id', value: userID},
       {name: 'token', value: token},
       {name: 'option_id', value: optionalParams.option_id},
       {name: 'organization_id', value: organizationID},
       {name: 'shipping_info', value: value}
    ]
    props.adminLoadState(updateParams, 'organizations', 'reload.persona');
  }
  
  return (
    <div className="margin-bottom-2em">
      <h4><IconSVG icon="shipping" /> Shipping Options</h4>
      {
        Array.isArray(optionList) && optionList.map((opt, index) => {
          return (
            <Fragment key={`prsnShpgOptn${index}`}>
              <div className="checbox-row">
                <Checkbox
                  key={`shOpt${index}`}
                  option_name={opt.option_name}
                  option_value={opt.option_value}
                  onClick={handleClick}
                  checked={opt.option_active}
                />
              </div>
              {props.objExists(opt, 'option_info') ?
                <div className="live-field-input-section">
                  <div className="live-field-input-heading">Shipping Info:</div>
                  <LiveFieldInput {...props}
                    key={`shInfoInput${index}`}
                    type="textarea"
                    name="shipping_info"
                    value={opt.option_info}
                    optionalParams={{option_id: opt.option_value, option_name: opt.option_name}}
                    action={updateShippingInfo}
                  />
                </div>
               :
                opt.option_active &&
                <Control 
                  key={`shInfoCtrl${index}`}
                  icon="shipping"
                  label="Add shipping info"
                  showLabel={true}
                  className="ctl-btn"
                  iconClass="no-btn"
                  action={()=>{loadAddEditShppingInfo(opt.option_value, null)}}
                />
              }
            </Fragment>
          )
        })
      }
    </div>
  )
  
  // return (
  //   <div className="list-set">
  //     <h4><IconSVG icon="shipping" /> Shipping Options</h4>
  //     {optionList &&
  //       <CheckList {...props} checklist={optionList} onClick={handleClick} />
  //     }
  //   </div>
  // )
}
