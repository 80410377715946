const apiRoot = 'https://api.aecore.app';
// const apiRoot = 'http://api.accellgraphics.ca';

// ADMIN
export const api_url_products = `${apiRoot}/internal/products/`;
export const api_url_types = `${apiRoot}/internal/types/`;
export const api_url_user = `${apiRoot}/users/`;
export const api_url_users = `${apiRoot}/users/`;
export const api_url_orders = `${apiRoot}/orders/`;
export const api_url_properties = `${apiRoot}/internal/properties/`;
export const api_url_categories = `${apiRoot}/store/`;
export const api_url_int_categories = `${apiRoot}/internal/categories/`;
export const api_url_organizations = `${apiRoot}/internal/organizations/`;
export const api_url_languages = `${apiRoot}/internal/languages/`;
export const api_url_locations = `${apiRoot}/internal/locations/`;
export const api_url_contacts = `${apiRoot}/internal/contacts/`;
export const api_url_mail = `${apiRoot}/mail/`;
export const api_url_links = `${apiRoot}/links/`;
export const api_url_common_tasks = `${apiRoot}/internal/commonTasks/`;
export const api_url_media = `${apiRoot}/internal/media/`;
export const api_url_messages = `${apiRoot}/internal/messages/`;
export const api_url_tasks = `${apiRoot}/internal/tasks/`;
export const api_url_status = `${apiRoot}/internal/status/`;
export const api_url_groups = `${apiRoot}/internal/groups/`;
export const api_url_files = `${apiRoot}/files/`;
export const api_url_site = `${apiRoot}/site/`;
export const api_url_store = `${apiRoot}/store/`;
export const api_url_fields = `${apiRoot}/internal/fieldsets/`;
export const api_url_pricing = `${apiRoot}/internal/pricing/`;
export const api_url_pdf = `${apiRoot}/pdf/`;

export const api_url_filetest = `${apiRoot}/files/create_pdf.php`;

// PUBLIC
export const api_url_rates = `${apiRoot}/shipping/`;

export function getReqUrl(api) {
    switch (api) {
        case 'properties':
        case 'property':
            return api_url_properties;
        case 'organizations':
        case 'organization':
            return api_url_organizations;
        case 'languages':
            return api_url_languages;
        case 'contact':
        case 'contacts':
            return api_url_contacts;
        case 'location':
        case 'locations':
            return api_url_locations;
        case 'products':
        case 'product':
            return api_url_products;
        case 'types':
            return api_url_types;
        case 'orders':
        case 'order':
            return api_url_orders;
        case 'users':
        case 'user':
            return api_url_users;
        case 'mail':
            return api_url_mail;
        case 'categories':
            return api_url_categories;
        case 'category':
        case 'category_int':
        case 'categories_int':
          return api_url_int_categories;
        case 'links':
        case 'link':
            return api_url_links;
        case 'commonTasks':
        case 'common_tasks':
            return api_url_common_tasks;
        case 'tasks':
        case 'task':
            return api_url_tasks;
        case 'field':
        case 'fields':
        case 'fieldsets':
        case 'fieldset':
            return api_url_fields;
        case 'pricing':
            return api_url_pricing;
        case 'status':
            return api_url_status;
        case 'site':
        case 'sites':
        case 'persona':
        case 'personas':
            return api_url_site;
        case 'media':
        case 'image':
        case 'video':
        case 'file':
            return api_url_media;
        case 'files':
            return api_url_files;
        case 'message':
        case 'messages':
            return api_url_messages;
        case 'rates':
        case 'tax':
        case 'shipping':
            return api_url_rates;
        case 'store':
            return api_url_store;
        case 'group':
        case 'groups':
            return api_url_groups;
        case 'pdf':
        case 'pdfs':
            return api_url_pdf;
        
        case 'filetest':
            return api_url_filetest;
        
        default:
          return null;
    }
}
