import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
	let fields = [
	  {field_type: 'text',
	  field_name: 'language_name',
	  field_label: `Name`,
	  field_value: props.objExists(props, 'language.language_name') ? props.language.language_name : null,
	  field_placeholder: 'Language Name',
	  field_order: 1,
	  field_required: true,
	  field_validate: true,},
	  {field_type: 'text',
		field_name: 'language_code',
		field_label: `Code`,
		field_value: props.objExists(props, 'language.language_code') ? props.language.language_code : null,
		field_placeholder: 'Language Code',
		field_order: 2,
		field_required: true,
		field_validate: true,},
	  {field_type: 'hidden',
		field_name: 'process',
		field_value: props.objExists(props, 'language.language_id') ? 'update_language' : 'add_language'},
	  props.objExists(props, 'language.language_id') &&
	  {field_type: 'hidden',
		field_name: 'language_id',
		field_value: props.language.language_id},
	  {field_type: 'hidden',
		field_name: 'user_id',
		field_value: props.userID,
	  },
	  {field_type: 'hidden',
		field_name: 'token',
		field_value: props.token,
	  },
	];
	
	setFieldsets([
	  {fieldset_name: `${props.objExists(props, 'language.language_id') ? 'Update' : 'Add'} Language`,
		fields: fields
	  }
	]);
  }, [props.language]);

  useEffect(() => {
	fieldsets &&
	setForm(
	  <FormDynamic {...props}
		  content={fieldsets}
		  name="add-edit-language"
		  returnObject={true}
		  fieldOnChange={null}
		  fieldOnLoad={null}
		  formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
	)
  }, [fieldsets]);

  function handleCancel() {
	props.setModal(null);
	props.actionCancel &&
	props.actionCancel();
  }

  function handleSubmit(params) {
	if(props.action) {
	  props.action(params);
	}
  }

  return (fieldsets && form)
}
