import React, {useEffect, useState, Fragment} from 'react';

import LanguageListItem from 'components/profiles/languages/LanguageListItem';
import FormLanguageAddEdit from 'components/forms/common_forms/FormLanguageAddEdit';
import Control from 'components/atoms/Control';

export default(props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.isSuper && props.isSuper !== userID) {
      setIsSuper(props.isSuper);
    }
  }, [props.isSuper]);
  
  useEffect(() => {
    if(userID && token && props.objGetValue(userAccess, 'super') === true) {
      const params = [
        {name: 'process', value: 'get_languages'},
        {name: 'organization_id', value: organizationID}
      ];
      if(!props.objExists(props.admin, 'languages') || 
        props.objGetValue(props.admin, 'reload.languages') === true) {
        props.adminLoadState(params, 'languages', 'languages');  
        props.adminDeleteVal('reload.languages');
      }
    }
  }, [userID, token, userAccess, props.admin.languages, props.admin.reload]);
  
  useEffect(() => {
    if(props.admin.languages && props.admin.languages !== languages) {
      setLanguages(props.admin.languages);
    }
  }, [props.admin.languages]);
  
  function loadAddLanguage() {
    props.setModal(
      <FormLanguageAddEdit {...props}
        userID={userID}
        token={token}
        action={addLanguage} />
    )
  }
  
  function addLanguage(params) {
    props.setModal(null);
    props.adminLoadState(params, 'languages', 'reload.languages');
  }
  
  return (
    userAccess && userAccess.super ?
      <div className="info-list">
        <h2>Languages</h2>
        {languages && Array.isArray(languages) &&
          languages.map((language, index) => {
            return <LanguageListItem {...props} 
              key={`crLng${index}`}
              userID={userID}
              token={token}
              isSuper={isSuper}
              userAccess={userAccess}
              language={language} />
          })
        }
        <Control icon="add" 
          className="margin-top-2em"
          label="Add Language"
          showLabel={true} 
          action={loadAddLanguage} />
      </div>
    :
      <div>
        <h2>NO ACCESS</h2>
      </div>
  )
}
