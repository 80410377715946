import React, {useEffect, useState} from 'react';

import Control from 'components/atoms/Control';
import FormArticleAddEdit from 'components/forms/common_forms/FormArticleAddEdit.js';
import IconToken from 'components/icons/IconToken';
import Article from 'components/molecules/Article';


export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [slideModal, setSlideModal] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(!languageCode && props.ssLanguage) {
			setLanguageCode(props.ssLanguage);
		}else if(!languageCode){
			setLanguageCode('en');
		}
	}, [props.ssLanguage]);
	
	// FUNCTIONS
	function showArticle(article, languageCode) {
		props.setModal(
			<Article {...props}
			  article={article}
			  languageCode={languageCode} />,
			true
		);
	}
	
	function loadAddEditArticle(article = null, lang = languageCode) {
		props.setModal(
			<FormArticleAddEdit 
				{...props}
				article={article ? article : null}
				userID={props.userID}
				token={props.token}
				languageCode={lang ? lang : 'en'}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={addEditArticle}
			/>
		)	
	};
	
	function addEditArticle(params) {
		props.setModal(null);
		console.log("Article Params", params);
		props[`${props.location ? props.location : 'admin'}LoadState`](params, 'site', 'reload.persona');
	}
	
	function loadRemove(slide) {
		console.log("Gonna remove a slide");
	}
	
	return (
		<div className="profile-component">
			<h5>Articles</h5>
			<div className="profile-component-set">
					{props.articles && 
						Array.isArray(props.articles)  && 
						props.articles.length > 0 &&
						props.articles.map((article, index) => {
							if(article && article.article_id) {
								return (
									<div className="profile-component-article" key={`prflCmpntArtcl${index}`}>
										<Article
											{...props}
											key={`pca${index}`}
											article={article}
											languageCode={languageCode} />
										<div className="ctl">
											<IconToken icon="preview"
														tooltip="Preveiw"
														action={()=>{showArticle(article, languageCode)}} />
											<IconToken icon="edit"
												tooltip="Edit"
												action={()=>{loadAddEditArticle(article, languageCode)}} />
											<IconToken icon="trash"
												tooltip="Remove"
												action={()=>{console.log("Remove");}} />
										</div>
									</div>
								)	
							}
						})
				}
			</div>
			<Control icon="add" label="Add an article" showLabel={true} action={loadAddEditArticle} />	
		</div>
	)
}