import React, { useRef, useEffect, useState, Fragment } from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconToggle from 'components/icons/IconToggle';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import CartItemDetails from 'components/cart/CartItemDetails';
import LiveFieldPrice from 'components/forms/elements/LiveFieldPrice';
import MediaToken from 'components/profiles/media/MediaToken';
import ActionsMenu from 'components/organisms/ActionsMenu';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const detailRef = useRef();
  const [languageCode, setLanguageCode] = useState(null);
  const [item, setItem] = useState(null);
  const [media, setMedia] = useState(null);
  const [hideLabel, setHideLabel] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [pricingOption, setPricingOption] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [qty, setQty] = useState(null);
  const [discounts, setDiscounts] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [pagePricingOption, setPagePricingOption] = useState(null);
  const [pageBasePrice, setPageBasePrice] = useState(null);
  const [pageDiscounts, setPageDiscounts] = useState(null);
  const [productCharge, setProductCharge] = useState(null);
  const [optionalCharges, setOptionalCharges] = useState(null);
  const [itemActive, setItemActive] = useState(false);
  const [isDigital, setIsDigital] = useState(false);
  const [multiLoc, setMultiLoc] = useState(false);

  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }else if(!languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.item && props.item !== item) {
      setItem(props.item);
    }
  }, [props.item]);
  
  useEffect(() => {
    if(props.isDigital && !isDigital) {
      setIsDigital(true);
    }else if(!props.isDigital && isDigital) {
      setIsDigital(false);
    }
  }, [props.isDigital]);
  
  useEffect(() => {
    if(props.expandView && !itemActive) {
      setItemActive(true);
    }
  }, [props.expandView]);
  
  useEffect(() => {
    if(props.objExists(props.site, `preferences`)) {
      if (props.site.preferences.hide_label && props.site.preferences.hide_label === true) {
        setHideLabel(true);
      }
    }
  }, [props.site]);
  
  useEffect(() => {
    if(item) {
      // PRODUCT LANGUAGE
      // if(item.language_code &&
      //   item.language_code !== languageCode) {
      //   setLanguageCode(item.language_code);
      // }else if(props.languageCode &&
      //         props.languageCode !== languageCode) {
      //   setLanguageCode(props.languageCode);  
      // }else{
      //   setLanguageCode('en');
      // }
      
      // MULTIPLE LOCATIONS
      if(props.objExists(item, `location_quantities`) &&
        Object.entries(item.location_quantities).length > 1 &&
        !multiLoc) {
          setMultiLoc(true);
      }else if(multiLoc) {
        setMultiLoc(false);
      }
      
    }
  }, [item]);
  
  useEffect(() => {
    if(props.objExists(item, `location_quantities`) &&
      Object.entries(item.location_quantities).length > 1 &&
      !multiLoc) {
        setMultiLoc(true);
    }else if(multiLoc) {
      setMultiLoc(false);
    }
  }, [props.multiLoc]);
  
  useEffect(() => {
    if(item && item.product_quantity && 
      parseInt(item.product_quantity) !== qty) {
      setQty(parseInt(item.product_quantity));
    }
  }, [item && item.product_quantity]);
  
  useEffect(() => {
    if(item && languageCode) {
      // SET ITEM MEDIA
      if(props.objExists(item, `details.${item.language_code}.media`) &&
        Array.isArray(item.details[item.language_code].media) &&
        item.details[item.language_code].media.length > 0) {
          const itemMedia = item.details[item.language_code].media.find(iMedia => iMedia.media_default === 1);
          if(itemMedia && itemMedia !== media) {
            setMedia(itemMedia);
          }else if(item.details[item.language_code].media[0] !== media){
            setMedia(item.details[item.language_code].media[0]);
          }
      }

      // SETTING FIELDS, MEDIA PARAMS AND OPTIONAL CHARGES
      if(props.objExists(props.item, `details.${item.language_code}.fieldsets`) &&
      Array.isArray(item.details[item.language_code].fieldsets)) {
        let fileFields = [];
        item.details[item.language_code].fieldsets.map(fieldset => {
          if(fieldset.fields && Array.isArray(fieldset.fields)) {
            fieldset.fields.map(field => {
              if(field.field_type === 'file' &&
                props.objExists(props.item, `field_values.${field.field_id}`)) {
                  fileFields.push({field_id: field.field_id, 
                                  field_value: parseInt(props.objGetValue(props.item, `field_values.${field.field_id}`))});
              }
              let tempOptionalCharges = [];
              if(props.objExists(field, `charges`) &&
                Array.isArray(field.charges) && 
                props.objExists(props.item, `field_values.${field.field_id}`) &&
                props.objGetValue(props.item, `field_values.${field.field_id}`) === 'true') {
                  field.charges.map(charge => {
                    tempOptionalCharges.push(charge);
                  })
                  setOptionalCharges(tempOptionalCharges);
              }
            });
          }
        });
        
        if(fileFields.length > 0) {
          let mediaParams = [
            {name: 'process', value: 'get_media'},
          ]
          if(fileFields.length > 1) {
            let mediaIDs = [];
            fileFields.map(fField => {
              mediaIDs.push(fField.field_value); 
            })
            mediaParams.push({name: 'media_id', value: mediaIDs});
          }else{
            mediaParams.push({name: 'media_id', value: fileFields[0].field_value});
          }
          if(!props.objExists(props.cart, `media.${props.index}`) || 
              !Array.isArray(props.cart.media[[props.index]]) ||
              !props.cart.media[props.index].find(cMedia => parseInt(cMedia.media_id) === parseInt(fileFields[0].field_value))) {
              props.cartLoadState(mediaParams, 'media', `media.${props.index}`);
          }
          
          
        }
      }
    }
  }, [item, languageCode]);
 
  useEffect(() => {
    if(languageCode && item.product_pricing_option && item.product_pricing_option !== '') {
        item.details[item.language_code] && item.details[item.language_code].pricing && Array.isArray(item.details[item.language_code].pricing) && item.details[item.language_code].pricing.length > 0 &&
          item.details[item.language_code].pricing.map((price, index) => {
            if(price.pricing_option === item.product_pricing_option) {
              pricingOption !== index &&
                setPricingOption(index);
              pricing !== price &&
                setPricing(price);
              price.discounts && 
                Array.isArray(price.discounts) && 
                price.discounts.length > 0 && 
                price.discounts !== discounts &&
                  setDiscounts(price.discounts);
            }
          })
    }
  }, [item, languageCode]);
  
  useEffect(() => {
    if(item && item.page_count && item.page_count !== pageCount) {
      setPageCount(item.page_count);
    }
  }, [item]);
  
  useEffect(() => {
    if(item && item.page_pricing_option && item.page_pricing_option !== pagePricingOption) {
      setPagePricingOption(item.page_pricing_option);
    }
  }, [item]);
  
  useEffect(() => {
    if(pageCount && pagePricingOption) {
      pageBasePrice !== parseFloat(pageCount * pagePricingOption.pricing_base_price) &&
      setPageBasePrice(parseFloat(pageCount * pagePricingOption.pricing_base_price));
    
      pagePricingOption.discounts && pageDiscounts !== pagePricingOption.discounts &&
      setPageDiscounts(pagePricingOption.discounts);
    }
  
  }, [pageCount, pagePricingOption]);
  
  useEffect(() => {
    if(props.site && item && languageCode) {
        calcProductCharge(qty) > 0 && setProductCharge(calcProductCharge(qty));
    }
  }, [props.site, item, languageCode]);

  // FUNCTIONS
  function toggleDetails(e) {
    if(detailRef.current.classList.contains('active')) {
      detailRef.current.classList.remove('active');
    }else{
      detailRef.current.classList.add('active');
    }
  }
  
  function updateItemNickname(fieldName, fieldValue) {
    props.cartSetValue(fieldName, fieldValue);
  }
  
  function calcProductCharge(itemQty) {
    if(props.objExists(props.site, `persona.charges.product[0].charge_value`)) {
      setProductCharge(props.site.persona.charges.product[0].charge_value);
    }
    if(props.objExists(item, `details.${item.language_code}.charges`) && Array.isArray(item.details[item.language_code].charges) && item.details[item.language_code].charges.length > 0) {
      let optionalCharge = 0;
      let productCharge = 0;
      if(optionalCharges && Array.isArray(optionalCharges) && optionalCharges.length > 0){
        optionalCharges.map((charge, index) => {
          if (charge.charge_multiply === 1 || charge.charge_multiply === true || charge.charge_multiply === 'true') {
            optionalCharge += parseFloat(charge.charge_value) * itemQty;
          } else {
            optionalCharge += parseFloat(charge.charge_value);
          }
        })
      }
      item.details[item.language_code].charges.map((charge, index) => {
        if (charge.charge_multiply === 1 || charge.charge_multiply === true || charge.charge_multiply === 'true') {
          productCharge += parseFloat(charge.charge_value) * itemQty;
        } else {
          productCharge += parseFloat(charge.charge_value);
        }
      })
      productCharge += optionalCharge;
      return productCharge;
    }
  }

  function updateItemQuantity(fieldName, fieldValue) {
    // Update product_price
    if(pricing) {
      const price = props.priceCalc(fieldValue, 
                                    props.numberInput(pricing.pricing_unit_price),
                                    props.numberInput(pricing.pricing_base_price),
                                    discounts,
                                    pricing.pricing_round,
                                    pageBasePrice,
                                    pageDiscounts,
                                    calcProductCharge(fieldValue)
                                  );
      props.cartSetValue(fieldName.replace('product_quantity', 'product_price'), price);
    }
    
    // Update location_quantities
    let locQtyFieldName;
    if (props.objExists(props.cart.project, 'orders')) {
      if (props.orderIndex == 'cart' && props.objExists(props.cart.project, `orders[0].items[${props.index}].location_quantities`)) {
        locQtyFieldName = 'project.orders[0].items[' + props.index + '].location_quantities[0]';
      } else if (props.objExists(props.cart.project, `orders[${props.orderIndex}].items[${props.index}].location_quantities`)) {
        locQtyFieldName = 'project.orders[' + props.orderIndex + '].items[' + props.index + '].location_quantities[0]';
      }
      if (locQtyFieldName !== '') {
        props.cartSetValue(locQtyFieldName, fieldValue);
      }
    }
    
    // Update product_quantity
    props.cartSetValue(fieldName, fieldValue);
    setQty(fieldValue);
  }
  
  // props.itemLocation project.orders[0].items[0]
  
  console.log('TEST languageCode', languageCode);
  
  return (
    <div className={`cart-box cart-item`}>
      {item && languageCode ?
          <Fragment>
            <MediaToken media={media} className="no-mobile" />
            <div className="cart-item-info">
              <div className={`list-row${itemActive ? ' active' : ''}`} style={{width: `${!props.hidePricing && qty !== null && pricing ? '90%' : '100%'}`}}>
                <div className="list-col-3">
                  {props.shipping_to && <div className="cart-item-info-shipping">Shipping to {props.shipping_to.location_name ? props.shipping_to.location_name : props.shipping_to.location_address_1}, {props.shipping_to.location_city}</div>}
                  <div className="cart-item-info-name">
                    <b style={isDigital ? {marginRight: '.5em'} : null}>
                      {props.objExists(item, `details.${item.language_code}.product_name`) ?
                                      item.details[item.language_code].product_name : 'Item'}
                      {isDigital && <Fragment>&nbsp;&nbsp;<IconSVG icon="save" className="p" /></Fragment>}
                    </b>
                    {!hideLabel &&
                      <LiveFieldInput {...props}
                        className={null}
                        name={`${props.itemLocation}.product_nickname`}
                        label="Label"
                        value={item.product_nickname}
                        action={updateItemNickname}
                        isDecimal={false}
                      />
                    }
                    {item && ((props.objExists(item, `field_values`) && Object.keys(item.field_values).length > 0) || (Array.isArray(item.selected_products) && item.selected_products.length > 0)) && 
                      <div className="cart-item-ctl-view">
                          <Fragment>
                            <span className="cart-label">Show Details</span>
                            <IconToggle active={itemActive} action={()=>{setItemActive(itemActive ? false : true)}} tooltip="Details" />
                          </Fragment>
                          <div className="cart-item-info-specs">
                            {item && ((props.objExists(item, `field_values`) && Object.keys(item.field_values).length > 0) || (Array.isArray(item.selected_products) && item.selected_products.length > 0)) &&
                              <CartItemDetails  {...props}
                                className={null}
                                index={props.index}
                                item={props.item}
                                languageCode={languageCode}
                                location={`${props.itemLocation}.field_values`}
                                field_values={item.field_values}
                                fieldsets={item.details[item.language_code].fieldsets}
                                fields={props.getAllFields(item.details[item.language_code].fieldsets)}
                                detailRef={detailRef}
                                itemActive={itemActive}
                                updateAction={props.updateAction} />
                            }
                          </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="list-col-1-center-center">
                  <div className="text-center">
                    {qty !== null &&
                      <div className="cart-item-info-specs-qty">
                        <div className="cart-label">Quantity</div>
                        <LiveFieldInput {...props}
                          className={null}
                          name={`${props.itemLocation}.product_quantity`}
                          value={qty}
                          type="number"
                          disabled={multiLoc ? true : false}
                          action={updateItemQuantity}
                          isDecimal={false}
                          />
                      </div>
                    }
                  </div>
                </div>
                {!props.hidePricing && qty !== null && pricing &&
                  <div className="list-col-1-center-right">
                  <div className="text-center">
                    <div className="cart-label margin-bottom-05em">Item Total</div>
                    <div className="cart-item-info-specs-price">{props.objExists(item, `product_price`) ? props.priceDisplay(item.product_price, languageCode) : props.priceDisplay(0, languageCode)}</div>
                  </div>
                  </div>
                }
              </div>
            </div>
            <div className={`cart-item-ctl${itemActive ? ' active' : ''}`}>
              <div className="cart-item-ctl-options">
                <ActionsMenu actions={props.itemActions} />
              </div>
            </div>
          </Fragment>
        :
          <Loader />
      }
    </div>
  )
}
