import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import FormLanguageAddEdit from 'components/forms/common_forms/FormLanguageAddEdit';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [language, setLanguage] = useState(null);
	const [form, setForm] = useState(null);
	const [editActive, setEditActive] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	  
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	  
	useEffect(() => {
		if(props.isSuper && props.isSuper !== isSuper) {
			setIsSuper(props.isSuper);
		}
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		}
	}, [props.userAccess])
		
	useEffect(() => {
		if(props.language && props.language !== language) {
		  setLanguage(props.language);
		}
	}, [props.language]);
	
	useEffect(() => {
		if(userID && token && language) {
			setForm(
				<FormLanguageAddEdit {...props}
					userID={userID}
					token={token}
					language={language}
					action={updateLanguage}
					actionCancel={()=>{setEditActive(false)}} />
			)
		}
	}, [userID, token, language, editActive])
	
	useEffect(() => {
		if(editActive && form) {
			props.setModal(form);
		}
	}, [editActive, form]);
	
	// FUNCTIONS
	function updateLanguage(params){
		props.setModal(null);
		setForm(null);
		setEditActive(false);
		props.adminLoadState(params, 'languages', 'reload.languages');
	}
	
	function loadRemoveLanguage() {
		props.setModal(
			<ModalConfirmation {...props}
				message={`Are you sure you want to remove the <b>${language.language_name}</b> language?`}
				actionAccept={removeLanguage} />
		)
	}
	
	function removeLanguage() {
		props.setModal(null);
		const params = [
		  {name: 'process', value: 'update_value'},
		  {name: 'field_id', value: language.language_id},
		  {name: 'field_name', value: 'language_removed'},
		  {name: 'field_value', value: true},
		  {name: 'update_id', value: userID}
		]
		props.adminLoadState(params, 'common_tasks', 'reload.languages');
	}
	
	return (
		language &&
		<div className="list-item">
			<div className="list-item-body" onClick={!editActive ? ()=> setEditActive(true) : null}>
				<b>{language.language_name}</b> | {language.language_code}
			</div>
			<div className="list-item-ctl">
				<IconToken {...props}
							className="btn" 
							icon="edit" 
							tooltip="Edit Language" 
							showTooltip={true} 
							action={!editActive ? ()=>{setEditActive(true)} : null} />
				<IconToken {...props}
							className="btn" 
							icon="trash" 
							tooltip="Remove Language"
							showTooltip={true} 
							action={loadRemoveLanguage} />
			</div>
		</div>
	)
}