import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router';

import Header from 'components/header/Header';
import Footer from 'components/organisms/Footer';
import Modal from 'components/organisms/Modal';
import Notification from 'components/organisms/Notification';

import * as actions from 'actions/';
import * as utils from 'methods/site';

const PageInternal = (props) => {
  props = {...props, ...utils};
  const [tokenCheck, setTokenCheck] = useState(null);
  const [userRequested, setUserRequested] = useState(null);
  const [userPending, setUserPending] = useState(true);
  const [loginTest, setLoginTest] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState({});
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userModules, setUserModules] = useState(null);
  const [allProps, setAllProps] = useState(props);
  const [childrenWProps, setChildrenWProps] = useState([]);
  
  let ivlUserTasks;
  let ivlMessages;
  let ivlPersona;
  let ivlModules;
  
  useEffect(() => {
    return() => {
      ivlUserTasks &&
      clearInterval(ivlUserTasks);
      ivlMessages &&
      clearInterval(ivlMessages);
      ivlPersona &&
      clearInterval(ivlPersona);
    }
  }, []);
  
  useEffect(() => {
    if(!utils.objExists(props.site, `persona.organization_id`)) {
        props.initSite(window.location);
    }else{
        setOrganizationID(props.site.persona.organization_id);
       // WILL RELOAD SITE PERSONA AFTER 5 MINUTES
       ivlPersona &&
       clearInterval(ivlPersona);
       
       ivlPersona = setInterval(()=> {
        if(document.visibilityState !== 'visible') { 
          props.initSite(window.location); 
        }
       }, props.minToMS(60));
    }
  }, [props.site]);
  
  useEffect(() => {
    if(utils.objExists(props.site, `persona.prefs`) && 
        Array.isArray(props.site.persona.prefs)) {
      let site_prefs = {};
      props.site.persona.prefs.map(pref => {
        site_prefs[pref.property_name] = pref.property_value === 'true' ? true :
                                            pref.property_value === 'false' ? false :
                                              pref.property_value ;
      })
      if(!utils.objExists(props.site, `preferences`) || 
          props.site.preferences !== site_prefs) {
          props.siteSetValue('preferences', site_prefs); 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.site.persona]);
  
  useEffect(() => {
    if(!loginTest && !window.location.pathname.includes('/login') && organizationID) {
      if(!utils.objExists(props.user, 'user_id')) {
          
        if(sessionStorage.getItem('token') && 
            setTokenCheck !== 'session' && !userRequested && setTokenCheck !== 'local') {
          const sessionParams = [
            {name: 'process', value: 'get_user_from_token'},
            {name: 'token', value: sessionStorage.getItem('token')},
            {name: 'organization_id', value: organizationID}
          ];
          props.userLoadState(sessionParams, 'users', null);
          
          setUserRequested(true);
          setTokenCheck('session');
        }else if (localStorage.getItem('token') && !userRequested && setTokenCheck !== 'local') {
          const localParams = [
            {name: 'process', value: 'get_user_from_token'},
            {name: 'token', value: localStorage.getItem('token')},
            {name: 'organization_id', value: organizationID}
          ]
          props.userLoadState(localParams, 'users', null);
          
          setUserRequested(true);
          setTokenCheck('token');
        }else if(userRequested) {
          setUserRequested(false);
        }else{
          setTokenCheck(null);
          setLoginTest(true);
        }
      }else if(!loginTest && props.objExists(props.user, 'user_id')) {
        setUserRequested(false);
        setTokenCheck(null);
        setLoginTest(true);
      }  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, organizationID, loginTest]);
  
  useEffect(() => {
    if(loginTest) {
      if(props.objExists(props.user, 'user_id')){
        if(props.user.user_id !== userID) {
          props.siteSetValue('reload.modules', true);
          setUserID(props.user.user_id);
        }
          
        if(sessionStorage.getItem('token') && sessionStorage.getItem('token') !== token) {
          setToken(sessionStorage.getItem('token'));
        }else if(localStorage.getItem('token')  && localStorage.getItem('token') !== token){
          setToken(localStorage.getItem('token'));
        }
          
      }else{
        !window.location.pathname.match('/login') &&
        props.siteSetValue('navigation.referrer', window.location.pathname);
        setRedirect('/login');
      }  
    }else if(userID){
      setUserID(null);
    }
  }, [props.user, loginTest, props.site.userUpdate]);
  
  useEffect(() => {
    if(organizationID) {
      if (props.objExists(props.site, 'language_code') && !languageCode) {
        setLanguageCode(props.site.language_code);
      }else if(!languageCode && !props.site.language_code) {
        let defaultLanguage = 'en';
        if(props.objExists(props.site, `persona.languages`) && 
          Array.isArray(props.site.persona.languages) && 
          props.site.persona.languages.length > 0) {
          let defaultLanguageObj = props.site.persona.languages.find(l => l.language_org_default === 1);
          if(defaultLanguageObj) {
            defaultLanguage = defaultLanguageObj['language_code'];
          }else{
            defaultLanguage = props.site.persona.languages[0].language_code;
          }
        }
        props.siteSetValue('language_code', defaultLanguage);
        setLanguageCode(defaultLanguage);
      }else if(languageCode && languageCode != props.site.language_code) {
        setLanguageCode(props.site.language_code);
      }
    }
  }, [organizationID, props.site.language_code]);
  
  useEffect(() => {
    if(userID && token){
      const taskParams = [
        {name: 'process', value: 'get_user_tasks'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'task_published', value: true},
        {name: 'task_completed', value: false}
      ];
      if(!props.objExists(props.user, `tasks`) ||
          props.objGetValue(props.user, `reload.tasks`) === true) {
            props.userLoadState(taskParams, 'tasks', 'tasks');
            if(props.objGetValue(props.user, `reload.tasks`) === true) {
              props.userRemoveVal('reload.tasks');
            }
            if(ivlUserTasks) {
              clearTimeout(ivlUserTasks);
            }
            ivlUserTasks = setInterval(() => {
              if(document.visibilityState === 'visible') {
                props.userLoadState(taskParams, 'tasks', 'tasks');
              }
            }, props.minToMS(5));
          }
    }
  }, [userID, token, organizationID, props.user.tasks, props.user.reload]);
  
  useEffect(() => {
    const messagesParams = [
      {name: 'process', value: 'get_user_messages'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
    ];
    
    if(!props.objExists(props.user, `messages`) ||
        props.objGetValue(props.user, `reload.messages`) === true) {
          props.userLoadState(messagesParams, 'messages', 'messages');
          if(props.objGetValue(props.user, `reload.messages`) === true) {
            props.userRemoveVal('reload.messages');
          }
          if(ivlMessages) {
            clearTimeout(ivlMessages);
          }
          ivlMessages = setInterval(() => {
            if(document.visibilityState === 'visible') {
              props.userLoadState(messagesParams, 'messages', 'messages');
            }
          }, props.minToMS(1));
    }
  }, [userID, token, props.user.messages, props.user.reload]);
  
  props = {
    ...props,
    userID: userID,
    token: token,
    organizationID: organizationID,
    languageCode: languageCode
  }
  
  let childrenWithProps = [];
  if(userAccess) {
    React.Children.forEach(props.children, (child, index)=> {
      childrenWithProps.push(React.cloneElement(child, {key: index}, {...props} ));
    })  
  }
  
  
  return(
    <Fragment>
      {redirect && <Redirect to={redirect} />}
      <Modal {...props} />
      <Header {...props} userAccess={userAccess} languageCode={languageCode} />
      <main>
          {childrenWithProps}
      </main>
      <Footer  {...props} {...utils}  userAccess={userAccess} />
      <Notification {...props.site.notification} actionRemove={props.removeNotification} {...utils} />
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    user: {...state.user},
    reqUrl: state.user.reqUrl,
    modal: state.modal.modal,
    site: state.site,
    form: state.form,
    store: state.store,
    cart: state.cart,
    admin: state.admin,
    dashboard: state.dashboard,
    files: state.files,
  };
}


export default connect(mapStateToProps, actions)(PageInternal);
