import React, {useEffect, useState} from 'react';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
    const [qty, setQty] = useState(0);
	const [baseQuantity, setBaseQuantity] = useState(null);
    const [pricingOption, setPricingOption] = useState(null);
    const [unitPrice, setUnitPrice] = useState(null);
	const [basePrice, setBasePrice] = useState(null);
	const [priceRound, setPriceRound] = useState(null);
    const [discounts, setDiscounts] = useState(null);
	const [discount, setDiscount] = useState(null);
	const [pageCount, setPageCount] = useState(null);
	const [pagePricingOption, setPagePricingOption] = useState(null);
	const [pageBasePrice, setPageBasePrice] = useState(null);
	const [pageDiscounts, setPageDiscounts] = useState(null);
	const [productCharge, setProductCharge] = useState(null);
	const [subtotal, setSubtotal] = useState(0);
	const [priceMsg, setPriceMsg] = useState(null);
	
	// HOOKS
	// SET LANGUAGE CODE
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
		setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.pricingOption && props.pricingOptions !== pricingOption) {
			setPricingOption(props.pricingOption);
		}
	}, [props.pricingOption]);
	
	useEffect(() => {
		if(props.baseQuantity && props.baseQuantity !== baseQuantity) {
			setBaseQuantity(props.baseQuantity);
		}
	}, [props.baseQuantity]);
	
	useEffect(() => {
		if(props.qty && props.qty !== qty) {
			setQty(props.qty);
		}
	}, [props.qty]);
	
	// SETTING UNIT PRICE, BASE PRICE, PRICE ROUNDING & DISCOUNTS
	// BASED ON PRICING OPTION
	useEffect(() => {
		if(pricingOption >= 0 && 
			props.pricing && 
			Array.isArray(props.pricing) && 
			props.pricing.length > 0 && 
			props.pricing[pricingOption]) {
		unitPrice !== parseFloat(props.pricing[pricingOption].pricing_unit_price) &&
		setUnitPrice(parseFloat(props.pricing[pricingOption].pricing_unit_price));
	
		basePrice !== parseFloat(props.pricing[pricingOption].pricing_base_price) &&
		setBasePrice(parseFloat(props.pricing[pricingOption].pricing_base_price));
	
		priceRound !== props.pricing[pricingOption].pricing_round &&
		setPriceRound(props.pricing[pricingOption].pricing_round);
	
		props.pricing[pricingOption].discounts &&
		discounts !== props.pricing[pricingOption].discounts &&
		setDiscounts(props.pricing[pricingOption].discounts)}
	
	}, [pricingOption]);
	
	useEffect(() => {
		if(props.pageCount && props.pageCount !== pageCount) {
			setPageCount(props.pageCount);
		}
	}, [props.pageCount]);
	
	useEffect(() => {
		if(props.pagePricingOption && props.pagePricingOption !== pagePricingOption) {
			setPagePricingOption(props.pagePricingOption);
		}
	}, [props.pagePricingOption]);
	
	// SETTING PAGE BASE PRICE & DISCOUNTS
	// BASED ON PAGE PRICING OPTION
	useEffect(() => {
		if(pagePricingOption && props.pagePricingOption) {
			pageBasePrice !== parseFloat(pageCount * props.pagePricingOption.pricing_base_price) &&
			setPageBasePrice(parseFloat(pageCount * props.pagePricingOption.pricing_base_price));
		
			props.pagePricingOption.discounts && pageDiscounts !== props.pagePricingOption.discounts &&
			setPageDiscounts(props.pagePricingOption.discounts);
		}
	
	}, [pageCount, pagePricingOption]);
	
	useEffect(() => {
		if(props.productCharge && props.productCharge !== productCharge) {
			setProductCharge(props.productCharge);
		} else {
			setProductCharge(0);
		}
	}, [props.productCharge]);
	
	useEffect(() => {
		if(qty && unitPrice) {
			setSubtotal(props.priceCalc(qty, unitPrice, basePrice, discounts, priceRound, pageBasePrice, pageDiscounts, productCharge));
		}
	}, [qty, unitPrice, basePrice, discounts, priceRound, pageBasePrice, pageDiscounts, productCharge]);
	
	// SETTING DISCOUNT AND PRICING NUDGE NOTICES
	useEffect(() => {
		let msg;
		setPriceMsg(null)
		if(qty >= baseQuantity) {
		if(discounts && Array.isArray(discounts) && discounts.length > 0) {
			let activeDiscounts = discounts.filter( discount => discount.discount_quantity > qty);
			if(activeDiscounts.length > 0) {
			activeDiscounts.sort((a,b) => a.discount_quantity > b.discount_quantity);
			let proximity = activeDiscounts[0].discount_proximity > 0 ? activeDiscounts[0].discount_proximity : parseInt(activeDiscounts[0].discount_quantity)*.05;
			if(proximity &&
				parseInt(qty) >= parseInt(proximity) && parseInt(qty) < activeDiscounts[0].discount_quantity) {
				msg = `You are ${parseInt(activeDiscounts[0].discount_quantity)-qty} away from saving ${Math.round(activeDiscounts[0].discount_percentage)}%!`;
			}
	
			}
		}
		msg && setPriceMsg(msg);
		} else if (qty < baseQuantity) {
		msg = `Your quantity is less than minimum order. You may order a lower quanity, however the price will not be reduced (Minimum price if for ${props.baseQty}).`;
		setPriceMsg(msg);
		}
	}, [qty, discounts]);
	
	useEffect(() => {
		if(props.actionPriceMsg) {
			props.actionPriceMsg(priceMsg);
		}
	}, [priceMsg])
	
	useEffect(() => {
		if(subtotal && props.actionUpdateSubtotal) {
			props.actionUpdateSubtotal(subtotal);
		}
	}, [subtotal]);
	
	return (
		!props.hidePricing &&
		<div className="product-details-ctl-price">
			<label>PRICE</label>
			<div>
				{props.priceDisplay(subtotal, languageCode)}
			</div>
			<div className="price-discount">
				{discount ? <sub>{` (${discount}% Discount)`}</sub> : ''}
			</div>
		</div>
	)
}