import React, {useEffect, useState} from 'react';
import { Redirect, useParams, useLocation, useHistory } from 'react-router';

import Select from 'components/forms/elements/Select';

import AdminProductAdd from 'components/admin/products/AdminProductAdd';
import AdminProductList from 'components/admin/products/AdminProductList';
import AdminProductInventory from 'components/admin/products/AdminProductInventory';
import AdminProductCategories from 'components/admin/products/AdminProductCategories';
import AdminProduct from 'components/admin/products/AdminProduct';
import AdminProductShell from 'components/admin/products/AdminProductShell';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [productComponent, setComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [token, setToken] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [curActivity, setCurActivity] = useState(null);
  const [optionsOrg, setOptionsOrg] = useState(null);
  const [selectOrg, setSelectOrg] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);

  const pgParams = useParams();
  const pgLocation = useLocation();
  const pgHistory = useHistory();
  
  let ivlProducts;
  
  useEffect(() => {
    return () => {
      if(ivlProducts) {
        clearInterval(ivlProducts);
      }
    }
  }, []);
  
  useEffect(() => {
    if(props.userID && userID !== props.userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && token !== props.token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }else if((!props.coreOrg || props.coreOrg <= 1) &&
              coreOrg) {
      setCoreOrg(null);
    }
  }, [props.coreOrg]);
  
  useEffect(() => {
    props.canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
    
  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.organizationName && organizationName !== props.organizationName) {
      setOrganizationName(props.organizationName);
    }
  }, [props.organizationName]);
  
  useEffect(()=> {
    if(props.objExists(props.admin, `organizations`) && 
      props.admin.organizations !== organizations) {
        setOrganizations(props.admin.organizations);
      }
  }, [props.admin.organizations]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(pgLocation.search) {
      const sParams = props.getURLSearchParams(pgLocation.search);
      if(parseInt(sParams.org) > 1 && parseInt(sParams.org) !== coreOrg) {
        // setCoreOrg(parseInt(sParams.org));
      }
    }
  }, [pgLocation]);
  
  useEffect(() => {
    const sParams = pgLocation.search ? props.getURLSearchParams(pgLocation.search) : {};
    if(coreOrg > 1 && organizationID === 1){
        if(parseInt(sParams.org) !== coreOrg) {
          sParams['org'] = coreOrg;
          
          let searchString = props.paramsToString(sParams);
          if(pgHistory.location.search !== searchString) {
            pgHistory.push(`${pgLocation.pathname}${searchString}`);
          }
        }
        
    }else if(organizationID === 1 && (!coreOrg || coreOrg <= 1) && sParams.org) {
      delete sParams.org;
      if(Object.entries(sParams).length > 0) {
        let searchString = props.paramsToString(sParams);
        if(pgHistory.location.search !== searchString) {
          pgHistory.push(`${pgLocation.pathname}${searchString}`);
        }
      }else{
        pgHistory.push(`${pgLocation.pathname}`);
      }
    }
  }, [coreOrg]);
  
  useEffect(() => {
    if(organizationID > 1 && organizationID !== coreOrg) {
      // setCoreOrg(organizationID);
    }else if(organizationID === 1 && searchParams &&
            parseInt(searchParams.org) > 1 &&
            parseInt(searchParams.org) !== coreOrg) {
      // setCoreOrg(parseInt(searchParams.org));
    }
  }, [searchParams, organizationID, coreOrg]);
  
  useEffect(() => {
    if(userID && token && (organizationID || coreOrg)) {
      
      // LOAD PRODUCTS
      const prodsParams = [
        {name: 'process', value: 'get_product_list'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: coreOrg ? coreOrg : organizationID},
        {name: 'language_code', value: languageCode},
        {name: 'product_published', value: false}
      ]

      if(!props.admin.products ||
          (coreOrg && Array.isArray(props.objGetValue(props.admin, `products`)) && props.admin.products.length > 0 &&
          props.admin.products.filter(prod => prod.organization_id === coreOrg).length <= 0) ||
          props.objGetValue(props.admin, 'reload.products') === true) {
        
        props.adminLoadState(prodsParams, 'products', 'products');
        if(props.objGetValue(props.admin, 'reload.products') === true) {
          props.adminDeleteVal('reload.products');
        }
        
        if(ivlProducts) {
          clearInterval(ivlProducts);
        }
        
        ivlProducts = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(prodsParams, 'products', 'products');
          }
        }, props.minToMS(60));
      }
    }
  }, [userID, token, organizationID, coreOrg, languageCode, props.admin.reload]);
  
  useEffect(() => {
    if(organizationID && userID && token) {
      let params = [
        {name: 'process', value: 'list_product_types'},
        {name: 'organization_id', value: organizationID > 1 ? organizationID : null },
        {name: 'user_id', value: userID},
        {name: 'token', value: token}
      ]
      
      if(!props.objExists(props.admin, 'product_types') ||
          props.objGetValue(props.admin, 'reload.product_types') === true ||
          (Array.isArray(props.objGetValue(props.admin, `product_types`) &&
            props.admin.product_types.filter(type => type.organization_id === coreOrg).length <= 0))) {
          props.adminLoadState(params, 'types', 'product_types');
          props.adminDeleteVal('reload.product_types');
      }
    } 
  }, [userID, token, organizationID, coreOrg, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && organizationID && languageCode) {
      const catParams = [
        {name: 'process', value: 'get_categories'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID > 1 ? organizationID : 0},
        {name: 'category_published', value: false},
        {name: 'language_code', value: languageCode}
      ]
      
      if(!props.objExists(props.admin, `categories.${languageCode}`) ||
        (Array.isArray(props.admin.categories[languageCode]) && 
        !props.admin.categories[languageCode].find(cat => cat.organization_id === coreOrg)) ||
        props.objGetValue(props.admin, `reload.categories`) === true) {
        
        if(props.objGetValue(props.admin, `reload.categories`) === true) {
          props.adminDeleteVal(`reload.categories`);
        }
        
        props.adminLoadState(catParams, 'categories_int', 'categories');
      }
      
    }
  }, [userID, token, organizationID, languageCode, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && organizationID) {
            
      // Set activity to load first products module available instead of 'product-list' by default
      let activity = pgParams.activity;
      if (!activity) {
        if(props.site.modules) {
          const modulesAdmin = props.site.modules.find(mod => mod.module_component === 'Admin');
          const modulesAdminProduct = modulesAdmin.modules.find(mod => mod.module_component === 'AdminProduct');
          if(props.objExists(modulesAdminProduct.modules[0][`${languageCode}`], 'module_link')) {
            activity = modulesAdminProduct.modules[0][`${languageCode}`].module_link;
          }
        }
      }
      // End
      
      switch(activity) {
        case 'product-add':
          setComponent(<AdminProductAdd {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            coreOrg={coreOrg}
                                            languageCode={languageCode}
                                            userAccess={userAccess}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            pgParams={pgParams}
                                            discardProduct={discardProduct} />);
          return;
        case 'product':
          setComponent(<AdminProduct {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            languageCode={languageCode}
                                            userAccess={userAccess}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            pgParams={pgParams}
                                            coreOrg={coreOrg}
                                            actionSelectOrg={orgSelect} />);
          return;
        case 'shell':
        setComponent(<AdminProductShell {...props}
                                        userID={userID}
                                        token={token}
                                        languageCode={languageCode}
                                        userAccess={userAccess}
                                        pgParams={pgParams} />);
          return;
        case 'categories':
          setComponent(<AdminProductCategories 
                            {...props}
                            userID={userID}
                            token={token}
                            organizationID={organizationID}
                            organizationName={organizationName}
                            organizations={organizations}
                            languageCode={languageCode}
                            userAccess={userAccess}
                            canEdit={canEdit}
                            isSuper={isSuper}
                            selectOrg={selectOrg}
                            pgParams={pgParams}
                            coreOrg={coreOrg}
                            actionSelectOrg={orgSelect} />);
          return;
        case 'product-inventory':
          setComponent(<AdminProductInventory {...props}
                            userID={userID}
                            token={token}
                            organizationID={organizationID}
                            organizationName={organizationName}
                            organizations={organizations}
                            languageCode={languageCode}
                            userAccess={userAccess}
                            canEdit={canEdit}
                            isSuper={isSuper}
                            pgParams={pgParams}
                            coreOrg={coreOrg}
                            actionSelectOrg={orgSelect} />);
          return;
        case 'product-list':
        default:
          setComponent(<AdminProductList {...props}
                            userID={userID}
                            token={token}
                            organizationID={organizationID}
                            organizationName={organizationName}
                            organizations={organizations}
                            languageCode={languageCode}
                            userAccess={userAccess}
                            canEdit={canEdit}
                            isSuper={isSuper}
                            pgParams={pgParams}
                            coreOrg={coreOrg}
                            actionSelectOrg={orgSelect} />);
          return;
      }
    }else{
      setComponent(<Loader />);
    }
  }, [props.pgParams,
      props.admin,
      props.files,
      userID, 
      token,
      organizationID, 
      organizationName,
      organizations,
      languageCode,
      userAccess,
      coreOrg]);
      
  useEffect(() => {
    if(props.objExists(props.admin, 'current.project')) {
      if(sessionStorage.getItem('admCurPrjct') !== props.admin.current.project) {
        sessionStorage.setItem('adminCurPrjct', JSON.stringify(props.admin.current.project));
      }
    }
  }, [props.admin.current]);

  // Functions
  function discardProduct() {
    props.adminDeleteVal('product');
    props.adminDeleteVal('productCtl');
    props.setModal(null);
    sessionStorage.removeItem('admPrd');
    sessionStorage.removeItem('admPrdCtl');
    sessionStorage.removeItem('filesUpl');
  }

  function loadCategories(organization_id = null) {
    const params = [
      {name: 'process', value: 'get_category_list'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
    ]
    if(organization_id) {
      params.push({name: 'organization_id', value: organization_id});
    }
    props.adminLoadState(params, 'categories', 'product_categories');
  }
  
  function orgSelect(org) {
    if(props.actionUpdateCoreOrg) {
      props.actionUpdateCoreOrg(org);
    }
  }
  
  return (
    <div className="admin-component-content">
      {props.admin.saveInProgress && redirect && <Redirect to={redirect} />}
      {productComponent}
    </div>
  )
}
